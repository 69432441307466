// @flow
import debug from '../debug';

const dlog = debug('arkadium-ads:services:ABTestManager');

const abTestManager = {
    abTestValue: '',
    adsABTestValue: '',
    sellersJSONABTestValue: '',
    setABTestValue: (inputValue: string) => {
        dlog('[setABTestValue] value: ', inputValue);
        abTestManager.abTestValue = inputValue;
    },
    setAdsABTestValue: (inputValue: string) => {
        dlog('[setAdsABTestValue] value: ', inputValue);
        abTestManager.adsABTestValue = inputValue;
    },
    splitAdsAbTest: (exposedTrafficPercentage: number, controlName: string, exposedName: string) => {
        dlog('[splitAdsAbTest] splitPercentage, controlName, exposedName: ', exposedTrafficPercentage, controlName, exposedName);
        if (abTestManager.adsABTestValue !== '') return abTestManager.adsABTestValue;
        const randomNumber = Math.random();
        if (randomNumber < exposedTrafficPercentage) {
            abTestManager.setAdsABTestValue(exposedName);
            return exposedName;
        }

        abTestManager.setAdsABTestValue(controlName);
        return controlName;
    },
};

export default abTestManager;
