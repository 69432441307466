// @flow
import debug from './debug';

import type { AdTypeEnum, ProviderType } from './core/ad-queue-item';
import type { PrebidLib } from './providers/prebid';
import { AdQueueItem, Providers } from './core/ad-queue-item';
import { CoreService } from './core/core-service';
import { getAttributeValue } from './utils';

const dlog = debug('arkadium-ads:core:BaseComponent');

/* eslint-disable */
// IE11 & EDGE fix
if (typeof HTMLElement === 'object') {
    const _Element = () => {};
    _Element.prototype = HTMLElement.prototype;
    HTMLElement = _Element;
}

export default function HTMLElementBase() {
    const newTarget = this.__proto__.constructor;
    if (typeof Reflect === "undefined") {
        /* $FlowFixMe */
        return HTMLElement.apply(Object.create(newTarget.prototype), []);
    } else {
        /* $FlowFixMe */
        return Reflect.construct(HTMLElement, [], newTarget);
    }
}

Object.setPrototypeOf(HTMLElementBase, HTMLElement);
Object.setPrototypeOf(HTMLElementBase.prototype, HTMLElement.prototype);
/* eslint-enable */

/* $FlowFixMe */
export class BaseComponent extends HTMLElementBase {
    externalComponentId: string = '';

    componentId: string = (
        (Math.random() * Number.MAX_SAFE_INTEGER).toString(36).replace(/[^a-z]+/g, '')
        + (Math.random() * Number.MAX_SAFE_INTEGER).toString(36).replace(/[^a-z]+/g, '')
    );

    coreService: CoreService = CoreService.getService();

    adQueueItem: AdQueueItem = new AdQueueItem();

    styles(): string {
        return '';
    }

    _exposeStyles() {
        let styleElement = document.querySelector(`head style[id=style_${this.externalComponentId || this.componentId}]`);
        if (!styleElement) {
            styleElement = document.createElement('style');
            styleElement.setAttribute('id', `style_${this.externalComponentId || this.componentId}`);
            styleElement.textContent = '';
            const headElement: any = document.querySelector('head');
            if (headElement) {
                headElement.appendChild(styleElement);
            }
        }

        const stylesValue = this.styles();
        dlog(`[_exposeStyles] for ${this.externalComponentId || this.componentId} style equals ${stylesValue}`);
        if (stylesValue && stylesValue !== styleElement.textContent) {
            styleElement.textContent = this.styles();
        }
    }

    template(args: ?string): string {
        return args ? '' : '';
    }

    render = () => {
        dlog('[render]');
        this.innerHTML = this.template(this.getAttribute('args'));
        this.initEvents();
    }

    initEvents() {}

    setConfig() {
        const attrs = {};
        for (let i = this.attributes.length - 1; i >= 0; i--) {
            if (this.attributes[i].name !== 'options') {
                attrs[this.attributes[i].name] = getAttributeValue(this.attributes[i].value);
            }
        }

        const options = getAttributeValue(this.getAttribute('options')) || {};

        this.adQueueItem.adConfig.fromJSON({ ...options, ...attrs });

        const providerValue = (this.getAttribute('provider') || '').toLowerCase();
        let providerType: ?ProviderType = null;

        switch (providerValue) {
            case 'glade': {
                providerType = Providers.GLADE;
                break;
            }

            case 'gtm': {
                providerType = Providers.GTM;
                break;
            }

            default: {
                providerType = Providers.GTM;
                break;
            }
        }

        this.adQueueItem.targetProvider = providerType;
    }

    connectedCallback() {
        dlog(`[connected] component: ${this.externalComponentId || this.componentId}`);
        this.setConfig();
        this.render();
        this._exposeStyles();
        this.show();
    }

    addPrebid(type: AdTypeEnum, prebidLib: PrebidLib, settings: Object, config: Object) {
        this.coreService.addPrebid(type, prebidLib, settings, config);
    }

    refresh() {
        this.coreService.registerAd(this.adQueueItem);
    }

    show() {
        this.coreService.registerAd(this.adQueueItem);
    }

    disconnectedCallback() {
        dlog(`[disconnected] compoennt: ${this.externalComponentId || this.componentId}`);
        this.coreService.disposeAd(this.adQueueItem);
    }
}
