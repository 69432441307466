// @flow

export class EventEmitter {
    events: any = {};

    addEventListener(event: string, listener: any) {
        this.events = this.events[event] || [];
        this.events.push(listener);
    }

    fireEvent(event: string, data: any) {
        (this.events[event] || []).forEach(action => action(data));
    }

    removeEventListener(event: string, listener: any) {
        if (this.events[event]) {
            const index = this.events[event].indexOf(listener);
            if (index !== -1) {
                this.events[event].splice(index, 1);
            }
        }
    }
}
