// @flow

import debug from './shared/debug';

const dlog = debug('arkadium-ads:video:utils');

export class LocalStorage {
    static getValue(key: string) {
        try {
            return window.localStorage.getItem(key);
        } catch (e) {
            return undefined;
        }
    }

    static setValue(key: string, value: string) {
        try {
            window.localStorage.setItem(key, value);
            return value;
        } catch (e) {
            return value;
        }
    }
}

export function getCookieValue(inputCookieName: string) {
    if (document.cookie.indexOf(inputCookieName) === -1) return undefined;
    let cookieValue = '';
    document.cookie.split('; ').forEach((cookieLine) => {
        if (cookieLine.indexOf(inputCookieName) !== -1) {
            cookieValue = cookieLine.split('=')[1]; // eslint-disable-line prefer-destructuring
        }
    });

    return cookieValue;
}

function removeVideoFromBody(video): any {
    try {
        // $FlowFixMe
        document.body.removeChild(video);
    } catch (e) {
        return e;
    }

    return null;
}

export function checkIsCanary() {
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.slice(-14) === 'display-ads.js') {
            if (scripts[i].src.split('/').indexOf('alpha') !== -1) return true;
            break;
        }
    }
    return false;
}
export function getBlobUrl(): string {
    return `https://arkadium-jarvis-api.azurewebsites.net/v1/adsBlob/compat/${window.location.hostname}`;
}

function createPlayer() {
    const videoEl = document.createElement('video');
    videoEl.setAttribute('playsinline', 'true');
    videoEl.style.display = 'block';
    videoEl.style.opacity = '0';
    videoEl.style.width = '640px';
    videoEl.style.height = '480px';
    videoEl.style.position = 'fixed';
    videoEl.style.left = '-100000px';
    return videoEl;
}

export function isVideoBlocked(
    isMuted: boolean,
    tryAgain: boolean = false,
    isReward: boolean = false,
): Promise<boolean> {
    const TIMEOUT_IN_MS = 1500;

    if (isReward) {
        // Don't waste video autoplays on reward videos. We should have 1 autoplay available here
        // This makes reward video work properly on iPhones
        return Promise.resolve(false);
    }

    return new Promise((resolve) => {
        let videoEl;

        const timeout = setTimeout(() => {
            dlog(`[isVideoBlocked] timeout ${TIMEOUT_IN_MS} milliseconds fired`);
            resolve(true);
        }, TIMEOUT_IN_MS);

        const success = () => {
            dlog('[isVideoBlocked] not blocked by browser (promise-then).');
            clearTimeout(timeout);
            removeVideoFromBody(videoEl);
            resolve(false);
        };

        const failure = (error) => {
            dlog(`[isVideoBlocked] blocked by browser (promise-catch). Message: ${error.message}`);
            clearTimeout(timeout);
            removeVideoFromBody(videoEl);
            if (tryAgain) {
                isVideoBlocked(isMuted, false).then(resolve);
            } else {
                resolve(true);
            }
        };

        videoEl = createPlayer();
        try {
            if (isMuted) {
                videoEl.setAttribute('muted', 'true');
            } else {
                videoEl.volume = 0.12;
            }
            videoEl.src = 'https://arenax-blobstorage.cdn.arkadiumhosted.com/arenax-index/_arena-shared-content_/tinyau.mp4';
            // $FlowFixMe
            document.body.appendChild(videoEl);

            // $FlowFixMe
            const promise = videoEl.play();
            dlog(`[isVideoBlocked] video promise = ${String(!!promise)}`);
            if (promise !== undefined) {
                promise.then(success, failure);
            } else {
                // edge-case for I.E. - Eric Yang 15 Oct 2019
                videoEl.onplaying = success;
            }
        } catch (e) {
            failure(e);
        }
    });
}

export function getScreenSize(playerWidth: number): Object {
    let screenWidth;
    let screenHeight;

    screenWidth = Math.min(window.innerWidth, window.outerWidth ? window.outerWidth : 9999999999);
    screenHeight = Math.min(window.innerHeight, window.outerHeight ? window.outerHeight : 9999999999);
    screenHeight = Math.min(screenWidth, screenHeight);
    screenWidth = Math.max(screenWidth, screenHeight);

    const containerWidth = playerWidth;
    const ratio = containerWidth < screenWidth ? 0.56 : screenHeight / screenWidth;
    const containerHeight = containerWidth * ratio;

    if (Math.abs(containerWidth - screenWidth) < 20) {
        return {
            height: Math.round(screenHeight),
            width: Math.round(screenWidth),
            addControlsSize: false
        };
    }

    return {
        height: Math.round(containerHeight),
        width: Math.round(containerWidth),
        addControlsSize: true
    };
}


export function setEventHandler(eventTarget: ?Object|?HTMLElement, event: string, fn: any) {
    if (eventTarget) {
        eventTarget.removeEventListener(event, fn);
        eventTarget.addEventListener(event, fn);
    }
}

export function getCookie(name: string): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }

    return '';
}

export function getDisplayAd() {
    return window && window.__ark_ads__ && window.__ark_ads__.displayAd;
}

/**
 * Generate random string
 *
 * @export
 * @returns {string}
 */
export function generateComponentId(): string {
    const rnd = () => Math.random() * Number.MAX_SAFE_INTEGER;
    return rnd().toString(36).replace(/[^a-z]+/g, '')
         + rnd().toString(36).replace(/[^a-z]+/g, '');
}

export function userDoesNotNeedGDPR() {
    const countriesThatNeedGDPR = [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
        'GB',
        'IS',
        'NO',
    ];

    const userIsNotInGDPRCountry = getCookieValue('arkadiumGeo') !== undefined && countriesThatNeedGDPR.indexOf(getCookieValue('arkadiumGeo')) === -1;
    if (userIsNotInGDPRCountry) return true;
    return false;
}

export const doesUserCountryCodeNeedGDPR = (inputCountryCode: string) => {
    const countriesThatNeedGDPR = [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PL',
        'PT',
        'RO',
        'SK',
        'SI',
        'ES',
        'SE',
        'GB',
        'IS',
        'NO',
    ];

    const userNeedsGDPR = countriesThatNeedGDPR.indexOf(inputCountryCode) !== -1;
    if (userNeedsGDPR) return true;
    return false;
};

export const waitForCMP = (resolve: any) => {
    const ignoreCmpAfter30Sec = setTimeout(() => {
        dlog('[gdprConsentPromise] skipping tcfChecker after 30seconds');
        resolve('skip after 30seconds');
    }, 30000);

    const tcfChecker = setInterval(() => {
        if (window.__tcfapi === undefined) return;
        clearTimeout(ignoreCmpAfter30Sec);
        window.__tcfapi('getTCData', 2, (e) => {
            let consentStringReady = e.eventStatus === 'useractioncomplete' || e.eventStatus === 'tcloaded';
            // specifically for Google Funding Choices because they dont set e.eventStatus on CMP load
            if (e.cmpId === 300) consentStringReady = e.tcString;

            if (consentStringReady) {
                dlog('[gdprConsentPromise] TC data ready');
                clearInterval(tcfChecker);
                resolve('GDPR TC string ready');
            }
        });
    });
};

export const userIsFromFbPromo = () => window.location.search.indexOf('utm_source=fb') !== -1 || window.localStorage.getItem('utm_source') === 'fb';
export const userIsFromAudigentFbPromo = () => window.location.search.indexOf('audigent_fb') !== -1 || (window.localStorage.getItem('arkpromo') && window.localStorage.getItem('arkpromo').indexOf('audigent_fb') !== -1);
