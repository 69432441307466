// @flow

import debug from '../shared/debug';

const dlog = debug('arkadium-ads:video:ima:AdsManager');


export class AdsManager {
    manager: Object;

    isMuted: boolean;

    init(adsManager: Object, isMuted: boolean, volume: number) {
        dlog(`[init] isMuted = ${String(isMuted)} has ads manager = ${String(!!adsManager)}`);
        this.manager = adsManager;
        if (isMuted) {
            this.manager.setVolume(0);
            this.isMuted = true;
        } else {
            this.manager.setVolume(volume);
        }
    }

    start(width: number = 640, height: number = 480) {
        dlog(`[start] width = ${width} height = ${height}`);
        const { ima } = window.google;
        this.manager.init(width, height, ima.ViewMode.NORMAL);
        this.manager.start();
    }

    setVolume(level: number) {
        this.manager.setVolume(level);
    }

    pause() {
        this.manager.pause();
    }

    resume() {
        this.manager.resume();
    }

    resize(width: number, heigth: number, viewMode: string) {
        this.manager.resize(width, heigth, viewMode);
    }

    addEventListener(event: string, fn: any) {
        this.manager.addEventListener(event, fn);
    }

    getRemainingTime() {
        return this.manager.getRemainingTime();
    }

    eventsForSubscriptions() {
        const { ima } = window.google;
        return {
            videoLoaded: [ima.AdEvent.Type.LOADED],
            videoStarted: [ima.AdEvent.Type.STARTED],
            videoPause: [ima.AdEvent.Type.PAUSED],
            videoResume: [ima.AdEvent.Type.RESUMED],
            videoImpression: [ima.AdEvent.Type.IMPRESSION],
            videoComplete: [
                ima.AdEvent.Type.ALL_ADS_COMPLETED,
                ima.AdEvent.Type.SKIPPED,
                ima.AdEvent.Type.COMPLETE
            ]
        };
    }

    destroy() {
        this.manager.destroy();
    }
}
