"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Analytics = void 0;
var Analytics = /** @class */ (function () {
    function Analytics() {
    }
    Analytics.getInstance = function () {
        if (!this._instance) {
            this._instance = new Analytics();
        }
        return this._instance;
    };
    Analytics.prototype.event = function (name, data) { };
    Analytics.prototype.error = function (err, details) { };
    return Analytics;
}());
exports.Analytics = Analytics;
