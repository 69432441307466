// @flow

import debug from '../shared/debug';
import { loadScript } from '../shared/utils';
import { getCookie } from '../utils';

const dlog = debug('arkadium-ads:video:reward:iron-source');

const SCRIPT_URL = 'https://static.ultra-rv.com/rv-min.js';

type IronSourceData = {
    applicationKey: string,
    rewardId?: string
}

export class IronSourceRewardVideo {
    ironRV: any = null;

    ironSourceReadyResolve: any = null;

    ironSourceCompletionResolve: any = null;

    hasReward: boolean = false;

    load(data: IronSourceData = {}): Promise<any> {
        dlog('[load] loading iron source');
        return loadScript(SCRIPT_URL).then(() => {
            const loggerData = JSON.stringify(data);
            dlog(`[load] init iron source for user ${getCookie('ark_pubCommonId')} with data ${loggerData}`);
            this.ironRV = window.IronRV.getInstance({
                applicationKey: data.applicationKey,
                applicationUserId: getCookie('ark_pubCommonId'),
                rewardId: data.rewardId
            });
            dlog(`[load] iron source instance = ${String(Boolean(this.ironRV))}`);
        });
    }

    events = {
        READY: (e: any) => {
            dlog('[ironSource] ready', e);
            this.ironSourceReadyResolve();
        },

        OPEN: (e: any) => {
            dlog('[ironSource] opened', e);
        },

        CLOSE: (e: any) => {
            dlog('[ironSource] closed', e);
            this.ironSourceCompletionResolve(this.hasReward);
            this.unsubscribe();
        },

        NO_CAMPAIGNS: (e: any) => {
            dlog('[ironSource] no compaings', e);
            this.hasReward = false;
            this.unsubscribe();
        },

        COMPLETION: (e: any) => {
            dlog('[ironSource] completion', e);
            this.hasReward = true;
        }
    }

    subscribeToEvents() {
        dlog('[subscribeToEvents]');
        const { IronRV } = window;

        Object.keys(this.events).forEach(event => (
            this.ironRV.addListener(IronRV.EVENTS[event], this.events[event])
        ));
    }

    unsubscribe() {
        dlog('[unsubscribe]');
        const { IronRV } = window;
        this.hasReward = false;

        Object.keys(this.events).forEach(event => (
            this.ironRV.removeListener(IronRV.EVENTS[event], this.events[event])
        ));

        this.hasReward = false;
        this.ironSourceReadyResolve = null;
        this.ironSourceCompletionResolve = null;
        this.ironRV.destroy();
        this.ironRV = null;
    }

    init(data: IronSourceData): Promise<any> {
        dlog('[init]');
        return new Promise((resolve, reject) => {
            this.ironSourceReadyResolve = resolve;
            this.load(data).then(() => {
                this.subscribeToEvents();
            }).catch(reject);
        });
    }

    show(): Promise<any> {
        dlog('[show]');
        return new Promise((resolve) => {
            this.ironSourceCompletionResolve = resolve;
            if (this.ironRV) {
                this.ironRV.show();
            }
        });
    }
}
