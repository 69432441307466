// @flow
import debug from '../debug';

const dlog = debug('arkadium-ads:services:BlobDataService');

export class BlobDataService {
    promise: Promise<any>;

    getBlob(): Promise<any> {
        dlog('[getBlob]');
        if (!this.promise) {
            this.promise = fetch(this.getBlobUrl()); // eslint-disable-line no-undef
        }

        return this.promise;
    }

    getBlobUrl(): string {
        return `https://arenacloud.cdn.arkadiumhosted.com/jarvis-api-prod/v1/adsBlob/compat/${window.location.hostname}`;
        // return `https://arkadium-jarvis-api.azurewebsites.net/v1/adsBlob/compat/${window.location.hostname}`;
    }
}
