// @flow

import prebid from 'prebid.js';
import 'prebid.js/modules/consentManagement';
import 'prebid.js/modules/consentManagementUsp';
import 'prebid.js/modules/gdprEnforcement';
import 'prebid.js/modules/schain';
import 'prebid.js/modules/priceFloors';

import 'prebid.js/modules/appnexusBidAdapter';
import 'prebid.js/modules/dfpAdServerVideo';
import 'prebid.js/modules/improvedigitalBidAdapter';
import 'prebid.js/modules/ixBidAdapter';
import 'prebid.js/modules/medianetBidAdapter';
import 'prebid.js/modules/openxBidAdapter';
import 'prebid.js/modules/pubmaticBidAdapter';
import 'prebid.js/modules/riseBidAdapter';
import 'prebid.js/modules/rhythmoneBidAdapter';
import 'prebid.js/modules/rubiconBidAdapter';
import 'prebid.js/modules/smartadserverBidAdapter';
import 'prebid.js/modules/sovrnBidAdapter';
import 'prebid.js/modules/teadsBidAdapter';
import 'prebid.js/modules/telariaBidAdapter';
import 'prebid.js/modules/tripleliftBidAdapter';
import 'prebid.js/modules/unrulyBidAdapter';
import 'prebid.js/modules/yahoosspBidAdapter';

export default prebid;
