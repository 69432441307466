export class AdUnit {
    code = '';

    sizes = [];

    dimensions = [];

    bids = [];

    mediaTypes = {};
}
