// @flow
import debug from '../debug';

import { AdQueueItem } from './ad-queue-item';
import { EventEmitter } from './event-emitter';

const dlog = debug('arkadium-ads:core:AdQueue');

export class AdQueue {
    static PROCESS_QUEUE_TIMEOUT: number = 3 * 100;

    eventEmitter: EventEmitter = new EventEmitter();

    queue: AdQueueItem[] = [];

    processCallback: (AdQueueItem[]) => Promise<string[]>

    processTimerId: TimeoutID;

    add(adRequest: AdQueueItem) {
        if (!this.queue.find(item => item === adRequest)) {
            this.queue.push(adRequest);
            dlog(`[add] items in queue ${this.queue.length}`);
            this.updateTimer();
        }
    }

    remove(adRequest: AdQueueItem) {
        this.queue.splice(this.queue.indexOf(adRequest), 1);
        dlog(`[remove] items in queue ${this.queue.length}`);
        if (this.queue.length) {
            this.updateTimer();
        } else {
            dlog('[remove] stop timer for processor');
            clearTimeout(this.processTimerId);
        }
    }

    updateTimer() {
        dlog('[updateTimer]');
        clearTimeout(this.processTimerId);
        this.processTimerId = setTimeout(
            () => this.processQueue(),
            AdQueue.PROCESS_QUEUE_TIMEOUT,
        );
    }

    processQueue() {
        if (this.processCallback) {
            this.processCallback(this.queue).then((ads) => {
                this.queue = this.queue
                    .filter(item => !ads.includes(item.componentId));
                dlog(`[processQueue] items in new queue ${this.queue.length}`);
            });
        }
    }
}
