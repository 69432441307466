// @flow
import debug from '../debug';
import { Amazon } from './amazon';
import { GTMProvider } from './dfp/gtm';

import { GladeProvider } from './dfp/glade';

import { AdType, Providers } from '../core/ad-queue-item';
import type { AdTypeEnum, AdQueueItem } from '../core/ad-queue-item';
import type { PrebidLib } from './prebid';
import { Prebid } from './prebid';
import { AdTagUrl } from '../../GoogleIMA/ad-tag-url';

const dlog = debug('arkadium-ads:providers:requests-processor');

export class RequestsProcessor {
    _prebids: Map<AdTypeEnum, Prebid> = new Map();

    GTMProvider: GTMProvider;

    amazon: Amazon = Amazon.getService();

    GladeProvider: GladeProvider;

    enableGTM(skip: ?boolean) {
        if (!this.GTMProvider) {
            dlog('[enableGTM]');
            this.GTMProvider = new GTMProvider(skip);
        }
    }

    enableGlade() {
        if (!this.GladeProvider) {
            dlog('[enableGlade]');
            this.GladeProvider = new GladeProvider();
            this.GladeProvider.renderPrebidCallback = this.renderPrebid;
            this.GladeProvider.renderAmazonCallback = this.renderAmazon;
        }
    }

    renderPrebid = (iframe: HTMLElement, adId: string) => {
        const prebid = this._prebids.get(AdType.DISPLAY);
        if (prebid) {
            prebid.renderAd(iframe, adId);
        }
    }

    renderAmazon = (iframe: HTMLElement, adId: string) => {
        if (this.amazon) {
            this.amazon.render(iframe, adId);
        }
    }

    addPrebid(type: AdTypeEnum, prebidLib: PrebidLib, settings: Object, config: Object) {
        if (!this._prebids.get(type)) {
            dlog(`[addPrebid] ${type}`);
            if (type === AdType.DISPLAY) {
                prebidLib.onEvent('bidWon', (arg) => {
                    dlog(`display bidWon ${arg.bidderCode} $${arg.cpm} ${arg.size} (${arg.adUnitCode}, ${arg.adId})`);
                });
            } else {
                prebidLib.onEvent('bidWon', (arg) => {
                    dlog(`video bidWon ${arg.bidderCode} $${arg.cpm} ${arg.size} (${arg.adUnitCode}, ${arg.adId})`);
                });
            }
            this._prebids.set(type, new Prebid(prebidLib, settings, config));
        }
    }

    cleanup(adUnit: AdQueueItem) {
        dlog(`[cleanup] adUnit: ${adUnit.componentId}`);
        const prebid = this._prebids.get(adUnit.type);
        const { componentId } = adUnit;
        if (prebid) {
            prebid.removeAdUnit(componentId);
        }

        if (this.GTMProvider) {
            this.GTMProvider.destroySlot(componentId);
        }
    }

    processGTMAds(prebid: Prebid, gtmDisplayAdsIds: string[] = []): Promise<string[]> {
        return new Promise((resolve) => {
            if (this.GTMProvider && gtmDisplayAdsIds.length > 0) {
                this.GTMProvider.addAction(() => {
                    prebid.addAction(() => {
                        dlog(`[processDisplayAd] set targeting ${gtmDisplayAdsIds.toString()}`);
                        prebid.setTargetingForGPTAsync(gtmDisplayAdsIds);
                        // actual request to DFP that will return AdX, prebid HB, Amazon or other eligible creative
                        dlog('[processDisplayAd] request ads');
                        this.GTMProvider.refreshSlots(gtmDisplayAdsIds);
                        resolve(gtmDisplayAdsIds);
                    });
                });
            } else {
                resolve(gtmDisplayAdsIds);
            }
        });
    }

    processGladeAds(prebid: Prebid, gladeDisplayAds: Object[] = []): Promise<string[]> {
        return new Promise((resolve) => {
            if (this.GladeProvider && gladeDisplayAds.length > 0) {
                prebid.addAction(() => {
                    const targeting = prebid.getAdserverTargeting();
                    gladeDisplayAds.forEach((item) => {
                        this.GladeProvider.render(
                            targeting[item.componentId],
                            item.adUnit,
                            item.adConfig,
                        );
                    });
                    resolve(gladeDisplayAds.map(i => i.componentId));
                });
            } else {
                resolve(gladeDisplayAds.map(i => i.componentId));
            }
        });
    }

    processDisplayAd(items: AdQueueItem[]): Promise<string[]> {
        return new Promise((resolve) => {
            const prebid = this._prebids.get(AdType.DISPLAY);
            if (prebid) {
                const displayAds = items.filter(item => item.type === AdType.DISPLAY);
                dlog(`[processDisplayAd] items count: ${displayAds.length}`);
                dlog(`[processDisplayAd] items: ${displayAds.map(ad => ad.componentId).toString()}`);
                displayAds.forEach((ad) => {
                    const adUnit = prebid.addAdUnit(ad.adUnit);
                    if (adUnit) {
                        if (this.GTMProvider && ad.targetProvider === Providers.GTM) {
                            this.GTMProvider.addAdUnit(
                                ad.adUnit,
                                ad.adConfig,
                            );
                        }
                    }
                });

                const gtmDisplayAdsIds = displayAds
                    .filter(item => (
                        item.targetProvider === Providers.GTM
                    )).map(i => i.componentId);
                const gladeDisplayAds = displayAds
                    .filter(item => item.targetProvider === Providers.GLADE);

                let prebidRequestFinished = false;

                this.amazon.createRequest(displayAds.filter(i => (
                    i.amazonSupported && i.adConfig.headerBidding
                ))).then((bids) => {
                    dlog(`[processDisplayAd] (amazon.createRequest) bids = ${JSON.stringify(bids)}`);
                    dlog(`[processDisplayAd] (amazon.createRequest) prebidRequestFinished = ${prebidRequestFinished.toString()}`);
                    if (gtmDisplayAdsIds.length > 0) {
                        dlog('[processDisplayAd] (amazon.createRequest) set bids for gpt');
                        this.amazon.setDisplayBids();
                    }
                    if (gladeDisplayAds.length > 0) {
                        dlog('[processDisplayAd] (amazon.createRequest) set bids for glade');
                        this.GladeProvider.setBidsInfo(bids);
                    }
                });

                prebid.requestBids(
                    displayAds.filter(ad => ad.adConfig.headerBidding).map(ad => ad.componentId),
                ).then(() => {
                    prebidRequestFinished = true;
                    Promise.all([
                        this.processGTMAds(prebid, gtmDisplayAdsIds),
                        this.processGladeAds(prebid, gladeDisplayAds),
                    ]).then(([gtm: string[] = [], glade: string[] = []]) => {
                        if (this.GladeProvider) {
                            this.GladeProvider.setBidsInfo([]);
                        }
                        resolve([...gtm, ...glade]);
                    });
                });
            } else {
                resolve([]);
            }
        });
    }

    processVideoAd(items: AdQueueItem[]): Promise<string[]> {
        const queueItems = items;
        return new Promise((resolve) => {
            const prebid = this._prebids.get(AdType.VIDEO);
            if (prebid) {
                dlog(`[processVideoAd] items count: ${queueItems.length}`);
                dlog(`[processVideoAd] items: ${queueItems.map(ad => ad.adUnit.code).toString()}`);
                queueItems.forEach(i => prebid.addAdUnit(i.adUnit));

                // ERICYANG July 2021 - I hate this but aliasBidder() has to be called after addAdUnit
                // https://github.com/prebid/Prebid.js/issues/780
                prebid.aliasBidder();

                const amazonAuction = this.amazon.createVideoRequest(
                    queueItems.filter(i => i.adConfig.headerBidding)
                );

                const prebidAuction = prebid.requestBids(
                    queueItems.filter(i => i.type === AdType.VIDEO).map(i => i.adUnit.code),
                );
                Promise.all([amazonAuction, prebidAuction])
                    .then((results) => {
                        const amazonBids = results[0];
                        dlog(`[processVideoAd] (amazon.createRequest) bids = ${JSON.stringify(amazonBids)}`);

                        const targeting = prebid.getAdserverTargeting() || {};
                        dlog(`[processVideoAd] targeting = ${JSON.stringify(targeting)}`);
                        if (targeting[queueItems[0].adUnit.code]
                            && Object.keys(targeting[queueItems[0].adUnit.code]).length !== 0) {
                            queueItems[0].adConfig.adUrl = prebid.buildVideoUrl({
                                adUnit: queueItems[0].adUnit,
                                params: queueItems[0].adConfig.adParamsFn(queueItems[0].adConfig),
                            });

                            if (amazonBids.length > 0) {
                                queueItems[0].adConfig.adUrl = AdTagUrl.appendAmazonUAMCustParam(
                                    queueItems[0].adConfig.adUrl,
                                    amazonBids[0].encodedQsParams
                                );
                            }

                            dlog(`[processVideoAd] adUrl from prebid = ${queueItems[0].adConfig.adUrl}`);
                        }
                        resolve(
                            queueItems.filter(i => i.type === AdType.VIDEO).map(i => i.adUnit.code),
                        );
                    });
            } else {
                resolve([]);
            }
        });
    }

    process(items: AdQueueItem[]) {
        return Promise.all([
            this.processDisplayAd(items), this.processVideoAd(items),
        ]).then(res => [...res[0], ...res[1]]);
    }
}
