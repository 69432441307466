"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleH5 = void 0;
var CONFIG = {
    'www.arkadium.com': {
        ca_pub: 'ca-pub-1748410580814249',
    },
};
var DEFAULT_CONFIG = {
    ca_pub: 'ca-pub-1116675408302866',
    //ca_host_pub: 'ca-host-pub-1748410580814249',
};
function init() {
    var _a = CONFIG[location.hostname] || DEFAULT_CONFIG, ca_pub = _a.ca_pub, ca_host_pub = _a.ca_host_pub;
    var script = document.createElement('script');
    var isTesting = location.href.includes('h5Debug=true');
    window.adsbygoogle = window.adsbygoogle || [];
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=' + ca_pub;
    script.crossOrigin = 'anonymouse';
    script.setAttribute('data-ad-client', ca_pub);
    if (ca_host_pub) {
        script.setAttribute('data-ad-host', ca_host_pub);
    }
    if (isTesting) {
        console.log('H5 IS IN TESTING MODE!');
        script.setAttribute('data-adbreak-test', 'on');
    }
    document.body.appendChild(script);
}
init();
var GoogleH5 = /** @class */ (function () {
    function GoogleH5(name) {
        this._optsSet = {};
        this._name = name;
        this._debug = location.href.includes('h5Debug=true');
        this._call({ preloadAdBreaks: 'on' });
    }
    GoogleH5.prototype._call = function (params) {
        this._debug && console.warn("H5", params);
        window.adsbygoogle.push(params);
    };
    GoogleH5.prototype.configure = function (config) {
        var _this = this;
        var unsetOpts = {};
        Object.keys(config).forEach(function (k) {
            if (_this._optsSet[k] === config[k])
                return;
            _this._optsSet[k] = unsetOpts[k] = config[k];
        });
        this._call(unsetOpts);
    };
    GoogleH5.prototype.run = function (params) {
        this._call(params);
    };
    GoogleH5.prototype.preroll = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var timeout = setTimeout(resolve, 45000);
            var finish = function () {
                clearTimeout(timeout);
                resolve({});
            };
            _this.run({
                type: 'preroll',
                name: 'preroll',
                adBreakDone: finish,
            });
        });
    };
    GoogleH5.prototype.reward = function (afterReward) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var timeout = setTimeout(resolve, 1000);
            _this.run({
                type: 'reward',
                name: _this._name,
                adBreakDone: afterReward,
                beforeReward: function (showAd) { return resolve(showAd); },
                adDismissed: function () { return console.log('ad dismissed'); },
                adViewed: function () { return console.log('ad viewed'); },
            });
        });
    };
    GoogleH5.prototype.next = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var timeout = setTimeout(resolve, 10000);
            var finish = function () {
                clearTimeout(timeout);
                resolve({});
            };
            _this.run({
                type: 'next',
                name: _this._name,
                beforeAd: function () { return console.log('Before AD'); },
                afterAd: function () { return finish(); },
                adBreakDone: function (s) { console.log(s); finish(); },
            });
        });
    };
    return GoogleH5;
}());
exports.GoogleH5 = GoogleH5;
