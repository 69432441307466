// @flow

import debug from '../debug';
import { AdQueueItem, Providers, AdType } from './ad-queue-item';
import { AdQueue } from './ad-queue';

import type { AdTypeEnum } from './ad-queue-item';
import type { PrebidLib } from '../providers/prebid';
import { RequestsProcessor } from '../providers/requests-processor';
import { PubmaticProvider } from '../providers/pubmatic';
import { CMPService, type TCData } from '../services/cmp-service';
import { BlobDataService } from '../services/blobDataService';


const dlog = debug('arkadium-ads:core:CoreService');
const dlogDev = debug('arkadium-ads-dev:core:CoreService');

export class CoreService {
    static instance: ?CoreService = null;

    adQueue: AdQueue = new AdQueue();

    onShowGTMEvent: any = null;

    onShowGladeEvent: any = null;

    onVideoTradeFinished: any = null;

    _requestsProcessor: RequestsProcessor = new RequestsProcessor();

    videoAdsBlobPromise: Promise<any>;

    pubmaticProvider = new PubmaticProvider();

    pubmaticIdHubPromise: Promise<any>;

    gdprConsentPromise: Promise<?TCData>;

    cmpService = new CMPService();

    blobDataService = new BlobDataService();

    constructor() {
        this.adQueue.processCallback = this.process;
        this.videoAdsBlobPromise = this.blobDataService.getBlob();
        this.gdprConsentPromise = this.cmpService.getTCData();
    }

    static getService() {
        if (!this.instance) {
            this.instance = new CoreService();
        }

        return this.instance;
    }

    registerAd(ad: AdQueueItem) {
        dlog('[registerAd]');
        this.adQueue.add(ad);
    }

    addPrebid(type: AdTypeEnum, prebid: PrebidLib, settings: Object, config: Object) {
        this._requestsProcessor.addPrebid(type, prebid, settings, config);
    }

    enableGTM(skip: ?boolean) {
        this._requestsProcessor.enableGTM(skip);
    }

    enableGlade() {
        this._requestsProcessor.enableGlade();
    }

    process = (queue: AdQueueItem[]): Promise<string[]> => this._requestsProcessor.process(queue)
        .then((items) => {
            dlogDev(`processed items ${items.toString()}`);
            dlogDev(`hasGtm event ${!!this.onShowGTMEvent}`);
            if (this.onShowGTMEvent) {
                this.onShowGTMEvent(
                    items.filter(i => !!queue.find(ad => (
                        ad.type === AdType.DISPLAY
                        && ad.targetProvider === Providers.GTM && i === ad.componentId
                    ))),
                );
            }

            if (this.onShowGladeEvent) {
                this.onShowGladeEvent(
                    items.filter(i => !!queue.find(ad => (
                        ad.type === AdType.DISPLAY
                        && ad.targetProvider === Providers.GLADE && i === ad.componentId
                    ))),
                );
            }

            if (this.onVideoTradeFinished) {
                this.onVideoTradeFinished(
                    items.filter(i => !!queue.find(ad => (
                        ad.type === AdType.VIDEO && i === ad.componentId
                    ))),
                );
            }

            return items;
        });

    disposeAd(adUnit: AdQueueItem) {
        this.adQueue.remove(adUnit);
        this._requestsProcessor.cleanup(adUnit);
    }
}
