import debug from './shared/debug';

const dlog = debug('arkadium-ads:video:back-compability');

export class AdVideo {
    constructor() {
        this.element = null;
        this.parent = null;
    }

    static preload() {}

    _hasErrors: false;

    _emitErrors(config, message) {
        if (config.onAdError) {
            config.onAdError(message);
        }
    }

    _emitComplete(config, data) {
        if (config.finalCallback) {
            config.finalCallback(data);
        }
    }

    showReward() {
        this.element.showReward();
    }

    render(config = { }) {
        dlog('[render]', config);

        if (this.element && this.element.parentNode) {
            // fix for arena 4.0
            if (this.parent) {
                this.parent.setAttribute('id', this.element.externalComponentId);
                this.parent.removeChild(this.element);
                this.element = null;
            }
        }

        this.element = document.createElement('video-ad-component');
        this.element.adQueueItem.adConfig.fromJSON(config);
        const { element } = config;

        this.parent = document.getElementById(element);
        this.element.addEventListener('AdError', (e) => {
            dlog(`[render] (AdError) hasCallback = ${!!config.onAdError} with message = ${(e.detail || {}).message}`);
            this._hasErrors = true;
            this._emitErrors(config, e.detail.message);
        });

        this.element.addEventListener('videoCompleted', (e) => {
            dlog(`[render] (videoCompleted) hasCallback = ${!!config.finalCallback}`);
            this._emitComplete(config, {
                ...(e.detail || {}),
                hasErrors: this._hasErrors,
            });

            this._hasErrors = false;
        });

        this.element.addEventListener('reward-ready', () => {
            dlog(`[render] (reward-ready) hasCallback = ${!!config.onRewardReady}`);
            if (config.onRewardReady) {
                config.onRewardReady();
            }
        });

        this.element.addEventListener('ctaStateChange', (event) => {
            dlog(`[render] (ctaStateChange) hasCallback = ${!!config.onCTAStateChange}`);
            if (config.onCTAStateChange) {
                config.onCTAStateChange(event.detail);
            }
        });

        if (this.parent) {
            this.parent.removeAttribute('id');
            this.element.externalComponentId = element; // eslint-disable-line
            this.parent.appendChild(this.element);
        }
    }
}

export function applyBackCompatibility() {
    window.__ark_ads__ = window.__ark_ads__ || {};
    window.__ark_ads__.AdVideo = AdVideo;
    window.__ark_ads__.ready = window.__ark_ads__.ready || function noop() { };
    window.__ark_ads__.ready(window.__ark_ads__.AdVideo, 'video');
}
