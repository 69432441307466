// @flow
import debug from '../debug';
import { AdQueueItem } from '../core/ad-queue-item';

const dlog = debug('arkadium-ads:providers:amazon');

export class Amazon {
    constructor() {
        this.init();
    }

    static instance: ?Amazon = null;

    static getService(): Amazon {
        if (!this.instance) {
            this.instance = new Amazon();
        }

        return this.instance;
    }

    init() {
        dlog('[init]');
        /*eslint-disable */
        !(function (a9, a, p, s, t, A, g) {
            if (a[a9]) return; function q(c, r) { a[a9]._Q.push([c, r]); }a[a9] = {
                init() { q('i', arguments); }, fetchBids() { q('f', arguments); }, setDisplayBids() {}, targetingKeys() { return []; }, _Q: [],
            }; A = p.createElement(s); A.async = !0; A.src = t; g = p.getElementsByTagName(s)[0]; g.parentNode && g.parentNode.insertBefore(A, g); window.apstag.init({ pubID: '661990cc-08cb-45bb-9ee2-90c2a9034640', adServer: 'googletag', videoAdServer: 'DFP' });
        }('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js'));
        /* eslint-enable */
    }

    fetchBids(options: Object = {}): Promise<any> {
        return new Promise((resolve) => {
            dlog(`[fetchBids] ${JSON.stringify(options)}`);
            dlog(`[fetchBids] has apstag ${!!(window.apstag && window.apstag.bids)}`);
            if (options && !!(window.apstag && window.apstag.bids)) {
                window.apstag.fetchBids(options, resolve);
            } else {
                resolve([]);
            }
        });
    }

    render(iframeElement: HTMLElement, adId: string) {
        dlog(`[render] adId = ${adId}`);
        if (window.apstag) {
            window.apstag.renderImp(iframeElement, adId);
        }
    }

    setDisplayBids() {
        dlog('[setDisplayBids]');
        if (window.apstag) {
            window.apstag.setDisplayBids();
        }
    }

    createRequest(displayAds: AdQueueItem[] = []): Promise<any> {
        dlog(`[createRequest] ads: ${displayAds.map(i => i.adUnit.code).toString()}`);
        if (displayAds.length === 0) return Promise.resolve([]);
        const slots = displayAds.map(item => ({
            slotID: item.adUnit.code,
            slotName: `/${item.adConfig.dfpNetworkCode}/${item.adConfig.adUnit}`,
            sizes: item.adConfig.dimensions,
        }));

        const { bidderTimeout } = displayAds[0].adConfig || 1200;

        return this.fetchBids(
            {
                slots,
                timeout: bidderTimeout,
            },
        );
    }

    createVideoRequest(videoAds: AdQueueItem[] = []): Promise<any> {
        dlog(`[createRequest] ads: ${videoAds.map(i => i.adUnit.code).toString()}`);
        if (videoAds.length === 0) return Promise.resolve([]);
        const slots = videoAds.map(() => ({
            slotID: 'desktop_web_640x480v',
            mediaType: 'video',
        }));

        const { bidderTimeout } = videoAds[0].adConfig || 1200;

        return this.fetchBids(
            {
                slots,
                timeout: bidderTimeout,
            },
        );
    }
}
