// @flow
import debug from '../shared/debug';
import GoogleIMA from './google-ima';
import { EventEmitter } from '../events';
import { AdsManager } from './ads-manager';
import { AdTagUrl } from './ad-tag-url';
import { getScreenSize } from '../utils';

const dlog = debug('arkadium-ads:video:ima:AdsLoader');

export class AdsLoader {
    element: ?HTMLElement;

    ratioKeeper: ?HTMLElement;

    preferredCreativeWidth = 640;

    preferredCreativeHeight = 360;

    events = new EventEmitter();

    adsManager: Object;

    adConfig: Object;

    adsLoader: any;

    progressInterval: IntervalID;

    static EVENTS = [
        'videoLoaded',
        'videoStarted',
        'videoImpression',
        'videoPause',
        'videoResume',
        'videoComplete',
        'videoProgress',
        'videoError'
    ]

    constructor(config: Object, element?: ?HTMLElement, ratioKeeper?: ?HTMLElement) {
        this.element = element;
        this.ratioKeeper = ratioKeeper;
        this.adConfig = config;

        GoogleIMA.setDisableCustomPlaybackForIOS10Plus(true);
        const container = GoogleIMA.AdDisplayContainer(element);

        if (container) {
            dlog('[ctor] init ad display container');
            container.initialize();
            dlog('[ctor] init ad loader');
            this.adsLoader = GoogleIMA.AdsLoader(container);
            if (this.adsLoader) {
                this.adsLoader.addEventListener(
                    GoogleIMA.AdsManagerLoadedEvent.ADS_MANAGER_LOADED,
                    this.onAdsManagerLoaded,
                    false
                );
                this.adsLoader.addEventListener(
                    GoogleIMA.AdErrorEvent.AD_ERROR,
                    this.onAdError,
                    false
                );
            }
        }
    }

    manageGDPRForRequest(url: string): string {
        const gdprEnabled = Boolean(
            this.adConfig.gdpr
            && this.adConfig.gdpr.enabled
        );

        if (!gdprEnabled) return url;

        const npaValue = (
            this.adConfig.gdpr.personalizedAds ? 0 : 1
        );

        const npa = url[url.length - 1] !== '&' ? `&npa=${npaValue}` : `npa=${npaValue}`;

        return url + npa;
    }

    requestAds() {
        const adsRequest = GoogleIMA.AdsRequest();
        adsRequest.adTagUrl = this.adConfig.adUrl || AdTagUrl.generate(this.adConfig);
        adsRequest.adTagUrl = this.manageGDPRForRequest(adsRequest.adTagUrl);
        adsRequest.setAdWillAutoPlay(this.adConfig.autoPlay);
        adsRequest.setAdWillPlayMuted(this.adConfig.mute);
        dlog(`[requestAds] ad tag url ${adsRequest.adTagUrl}`);
        // Specify the linear slot sizes. This helps the SDK to select the correct creative if multiple are returned.
        adsRequest.linearAdSlotWidth = this.preferredCreativeWidth;
        adsRequest.linearAdSlotHeight = this.preferredCreativeHeight;

        this.adsLoader.requestAds(adsRequest);
    }

    onAdError = (err: Object) => {
        const message = (err && err.getError()) || 'Unexpected AdsLoader error';

        dlog(`[onAdError] ${message}`);

        this.events.fire('videoError', {
            adsManager: this.adsManager,
            adEvent: null,
            message
        });
    }

    subscribeToAdsManagerEvents() {
        const eventsMap = this.adsManager.eventsForSubscriptions();

        Object.keys(eventsMap).forEach((libEvent) => {
            eventsMap[libEvent].forEach((managerEvent) => {
                this.adsManager.addEventListener(managerEvent, (adEvent) => {
                    this.events.fire(libEvent, {
                        adsManager: this.adsManager,
                        adEvent
                    });
                });
            });
        });
    }

    onAdsManagerLoaded = (adsManagerLoadedEvent: Object) => {
        this.adsManager = new AdsManager();
        if (this.element) {
            this.adsManager.init(
                adsManagerLoadedEvent.getAdsManager(this.element.querySelector('video')),
                this.adConfig.mute,
                this.adConfig.volume,
            );

            this.subscribeToAdsManagerEvents();


            /* $FlowFixMe */
            const { width, height } = getScreenSize(this.ratioKeeper.parentNode.clientWidth);
            if (document.visibilityState === 'hidden') {
                document.addEventListener('visibilitychange', () => {
                    if (document.visibilityState === 'visible') {
                        this.adsManager.start(width, height);
                        this.adsManager.addEventListener(GoogleIMA.AdErrorEvent.AD_ERROR, this.onAdError);
                    }
                });
            } else {
                this.adsManager.start(width, height);
                this.adsManager.addEventListener(GoogleIMA.AdErrorEvent.AD_ERROR, this.onAdError);
            }
        }
    }

    resize(clientWidth: number, clientHeight: number) {
        if (this.adsManager) {
            this.adsManager.resize(clientWidth, clientHeight, window.google.ima.ViewMode.NORMAL);
        }
    }

    _onProgress = () => {
        this.events.fire('videoProgress', { adsManager: this.adsManager });
    }

    startProgressUpdateCycle() {
        clearInterval(this.progressInterval);
        this.progressInterval = setInterval(this._onProgress, 500);
    }

    stopProgressUpdateCycle() {
        clearInterval(this.progressInterval);
    }

    destroy() {
        dlog(`[destroy] has adsManager ${!!this.adsManager}`);
        if (this.adsManager) {
            this.adsManager.destroy();
        }

        dlog(`[destroy] has adsLoader ${!!this.adsLoader}`);
        if (this.adsLoader) {
            this.adsLoader.destroy();
        }
    }
}
