export class Domains {
    static biddersList(domain) {
        const filterByDomain = this.bidders.filter((item) => {
            if (domain === 'prebid' || domain === 'prebid2') return true;
            if (domain.indexOf('prebid/') >= 0) return (domain.indexOf(`prebid/${item.bidder}`) >= 0);
            if (item.exclude) {
                return !item.exclude.find(siteDomain => siteDomain === domain);
            }

            if (item.include) {
                return item.include.find(siteDomain => siteDomain === domain);
            }

            return true;
        });

        return filterByDomain.map(item => item.bidder);
    }

    static setBidders(inputBidders) {
        this.bidders = inputBidders;
    }

    static getApprovedBids(bids: Object[], domain: string): Object[] {
        const biddersForDomain = this.biddersList(domain);
        return bids.filter(bid => biddersForDomain.find(
            bidder => bid.bidder === bidder,
        ));
    }

    static getAllBidders() {
        return this.bidders;
    }
}
