// @flow
import debug from '../../debug';
import {
    loadScript, isWebView, getPagePath, getHostDomain,
} from '../../utils';

import { AdUnit } from '../units';

import { AdDisplayConfig } from '../../configs/display-config';

const dlog = debug('arkadium-ads:providers:gtm');

export class GTMProvider {
    constructor(skip: ?boolean) {
        dlog('[ctor]');

        if (!window.googletag || !window.googletag.cmd) {
            window.googletag = {};
            window.googletag.cmd = [];
        }

        if (!skip) {
            window.googletag.cmd.push(() => {
                // postpone ads request, ads will be requested on googletag.pubads().refresh
                window.googletag.pubads().disableInitialLoad();
                // combine multiple ads on the page in one call to DFP
                window.googletag.pubads().enableSingleRequest();
                window.googletag.enableServices();
            });
        }

        this.init();
    }

    trackPagePath: boolean = false;

    init() {
        if (!window.googletag.apiReady) {
            return loadScript('//www.googletagservices.com/tag/js/gpt.js');
        }

        return Promise.reject(new Error('GTM API Is not ready'));
    }

    addAdUnit({ sizes, code }: AdUnit, adConfig: AdDisplayConfig) {
        dlog('[addAdUnit]');
        window.googletag.cmd.push(() => {
            const slot = window.googletag.defineSlot(`/${adConfig.dfpNetworkCode}/${getHostDomain(adConfig.adUnit)}`, sizes, code);
            slot.setTargeting('dfpPrebid', ['1']); // indicates that call to DFP was from this library (arkGoogleTag)
            if (adConfig.trackElementID) {
                slot.setTargeting('elementId', code); // optional ad DOM id
            }
            if (adConfig.trackWebView && isWebView()) {
                slot.setTargeting('webView', 1); // optional tracking on webView
            }
            if (adConfig.product) {
                slot.setTargeting('product', adConfig.product); // optional Arkadium product
            }

            (adConfig.keyValues || []).forEach(([k, v]) => slot.setTargeting(k, v));
            slot.addService(window.googletag.pubads());
        });

        // this will not load ad since we use disableInitialLoad
        window.googletag.cmd.push(() => {
            window.googletag.display(code);
        });
    }

    addAction(action: any) {
        window.googletag.cmd.push(() => action());
    }

    initAdServer(adUnitCodes: string[] = [], loadingType: string) {
        dlog('[initAdServer]');
        window.googletag.cmd.push(() => {
            window.arkadium_pbjs.que.push(() => {
                // add header bidding winner data as key/values
                window.arkadium_pbjs.setTargetingForGPTAsync(adUnitCodes);
                // actual request to DFP that will return AdX, prebid HB, Amazon or other eligible creative
                window.googletag.pubads()
                    .refresh(this.getSlots(adUnitCodes, loadingType));
                // baseConfig.onShow(adUnitCodes); // report fact of ad request to callback function provided
            });
        });
    }

    refreshSlots(adUnitCodes: string[] = [], loadingType: string = 'refresh') {
        window.googletag.pubads().refresh(this.getSlots(adUnitCodes, loadingType));
    }

    getSlots(adUnitCodes : string[], loadingType : string): any {
        dlog('[getSlots]');
        const slots = window.googletag.pubads().getSlots();
        return slots.filter(slot => adUnitCodes.find((adUnitCode : string) => {
            const matched = adUnitCode === slot.getSlotElementId();
            if (matched) {
                // optionally set loadingType key/value for matched slot
                if (loadingType) slot.setTargeting('loadingType', loadingType);
                // optional page path. Need do it here for SPA sites
                if (this.trackPagePath) slot.setTargeting('pagePath', getPagePath());
            }
            return matched;
        }));
    }

    destroySlot(adUnitCode : string) {
        dlog(`[destroySlot] item: ${adUnitCode}`);
        window.googletag.cmd.push(() => {
            const slots = window.googletag.pubads().getSlots();
            const slot = slots.find(item => item.getSlotElementId() === adUnitCode);
            if (slot) {
                window.googletag.destroySlots([slot]);
            }
        });
    }
}
