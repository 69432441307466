/* eslint-env browser */
// @flow

function localStorageGet(item: string) {
    try {
        return localStorage.getItem(item) || '0';
    } catch (e) {
        console.error(e); // eslint-disable-line
        return '';
    }
}

function addColor(str: string) {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
        // IE11 doesn't support colors
        return [str];
    }
    const rest = (str.split(':').slice(-1)[0].charCodeAt(0)).toString(16);
    return [`%c ${str}`, `color: #a${rest}`];
}

export default function debugFactory(name: string) {
    const isEnabled = new RegExp(localStorageGet('debug')).test(name);
    const colorName = addColor(name);
    return isEnabled ? console.log.bind(console, ...colorName) : (...args: any[]) => null;  // eslint-disable-line
}
