// @flow
import { loadScript } from '../shared/utils';

const IMA_SCRIPT_URL = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';

class GoogleIMA {
    _ima: ?Object = null;

    loadIMA(): Promise<any> {
        return loadScript(IMA_SCRIPT_URL).then(() => {
            this._ima = window.google.ima;
        });
    }

    AdDisplayContainer(element: ?HTMLElement): ?Object {
        if (this._ima) {
            return new this._ima.AdDisplayContainer(element);
        }

        return null;
    }

    AdsLoader(container: Object): ?Object {
        if (this._ima) {
            return new this._ima.AdsLoader(container);
        }

        return null;
    }

    setDisableCustomPlaybackForIOS10Plus(status: boolean) {
        if (this._ima) {
            this._ima.settings.setDisableCustomPlaybackForIOS10Plus(status);
        }
    }

    get AdsManagerLoadedEvent(): Object {
        if (this._ima) {
            return this._ima.AdsManagerLoadedEvent.Type;
        }

        return {};
    }

    get AdErrorEvent(): Object {
        if (this._ima) {
            return this._ima.AdErrorEvent.Type;
        }

        return {};
    }

    get AdEvent(): Object {
        if (this._ima) {
            return this._ima.AdEvent.Type;
        }

        return {};
    }

    get settings(): Object {
        if (this._ima) {
            return this._ima.settings;
        }

        return {};
    }

    get ImaSdkSettings(): Object {
        if (this._ima) {
            return this._ima.ImaSdkSettings;
        }

        return {};
    }

    AdsRequest(): Object {
        if (this._ima) {
            return new this._ima.AdsRequest();
        }

        return {};
    }
}

export default new GoogleIMA();
