// @flow

(function CustomEventPolyfill() {
    if (typeof window.CustomEvent === 'function') return false;

    function CustomEvent(event, params) {
        const args = params || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, args.bubbles, args.cancelable, args.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;

    return true;
}());
