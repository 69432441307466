// @flow
/* eslint-env browser */

export class PubmaticProvider {
    init() {
        // pubmatic ID Hub
        if (!window.PWT) {
            const purl = window.location.href;
            const url = '//ads.pubmatic.com/AdServer/js/pwt/159272/3362';
            let profileVersionId = '';
            if (purl.indexOf('pwtv=') > 0) {
                const regexp = /pwtv=(.*?)(&|$)/g;
                const matches = regexp.exec(purl);
                // $FlowFixMe
                if (matches.length >= 2 && matches[1].length > 0) {
                    // $FlowFixMe
                    profileVersionId = `/${matches[1]}`;
                }
            }
            const wtads = document.createElement('script');
            wtads.async = true;
            wtads.type = 'text/javascript';
            wtads.src = `${url}${profileVersionId}/pwt.js`;
            const node = document.getElementsByTagName('script')[0];
            // $FlowFixMe
            node.parentNode.insertBefore(wtads, node);
        }
        // END pubmatic ID Hub

        return new Promise<any>((resolve) => {
            const pwtChecker = setInterval(() => {
                if (window.PWT) {
                    clearInterval(pwtChecker);
                    resolve();
                }
            }, 100);
        });
    }
}
