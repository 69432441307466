const COMP_NAME = 'skip-button';

export class SkipButton extends HTMLElement {
    _interval = 0;

    _left = window.location.port === '8080' ? 5 : 30;

    connectedCallback() {
        this._interval = setInterval(this.render.bind(this), 1000);
        this.style.display = 'none';
        this.style.position = 'absolute';
        this.style.top = '0';
        this.style.right = '0';
        this.style.transform = 'translate(0, -200px)';
        this.style.transition = '1s all ease-out';

        this.innerHTML = `
            <button class="ctaButton" type="button">Skip this video</button>
        `;
    }

    render() {
        if (this._left > 0) {
            if (document.hasFocus()) {
                // Only count seconds while window has focus (the tab is active)
                this._left--;
            }
        } else {
            // The desired time has passed
            clearInterval(this._interval);
            this.style.display = 'block';
            setTimeout(() => {
                this.style.transform = 'translate(0, 0)';
            }, 100);
        }
    }
}

if (!customElements.get(COMP_NAME)) {
    customElements.define(COMP_NAME, SkipButton);
}
