export const PREBID_SETTINGS = {
    standard: {
        adserverTargeting: [{
            key: 'hb_bidder',
            val(bidResponse) {
                return bidResponse.bidderCode;
            }
        }, {
            key: 'hb_adid',
            val(bidResponse) {
                return bidResponse.adId;
            }
        }, {
            key: 'hb_cpm',
            val(bidResponse) {
                return bidResponse.cpm.toFixed(2);
            }
        }]
    },
};

const schainSids = {
    'mobileposse.com': 'ark-0001',
    'puzzles.ca': 'ark-0002',
    'games.dailystar.co.uk': 'ark-0003',
    'arcade.hsn.com': 'ark-0004',
    'puzzles.msn.com': 'ark-0005',
    'games.metv.com': 'ark-0006',
    'puzzles.bestforpuzzles.com': 'ark-0007',
    'gammes.chicagotribune.com': 'ark-0008',
    'games.mirror.co.uk': 'ark-0009',
    'juegos.elpais.com': 'ark-0010',
    'games.readersdigest.ca': 'ark-0011',
    'games.usatoday.com': 'ark-0012',
    'puzzles.usatoday.com': 'ark-0012',
    'jeux.lemonde.fr': 'ark-0013',
    'puzzles.nj.com': 'ark-0014',
    'puzzles.independent.co.uk': 'ark-0015',
    'games.xfinity.com': 'ark-0082',
    'games.dailymail.co.uk': 'ark-0081',
};

export const PREBID_CONFIG = {
    usePrebidCache: true,
    enableSendAllBids: false,
    cache: {
        url: 'https://prebid.adnxs.com/pbc/v1/cache'
    },
    userSync: {
        syncEnabled: true,
        syncDelay: 5000,
        syncsPerBidder: 2,
        userIds: [{
            name: 'pubCommonId',
            storage: {
                type: 'cookie',
                name: 'ark_pubCommonId',
                expires: 60,
            },
        }, {
            name: 'unifiedId',
            params: {
                url: '//match.adsrvr.org/track/rid?ttd_pid=71nw1m8&fmt=json',
            },
            storage: {
                type: 'cookie',
                name: 'ark-pbjs-unifiedid',
                expires: 60,
            },
        }],
    },
};

if (schainSids[window.location.hostname]) {
    PREBID_CONFIG.schain = {
        config: {
            ver: '1.0',
            complete: 1,
            nodes: [{
                asi: 'arkadium.com',
                sid: schainSids[window.location.hostname],
                hp: 1,
            }],
        },
    };
}
