// @flow
/* eslint import/extensions: 0 */
import './polyfills';
import '@webcomponents/custom-elements';
import './html-player/index';
import './styles.css';

import debug from './shared/debug';
import {
    getParentTCF, getAttributeValue, isMobile, loadScript
} from './shared/utils';
import { AdType } from './shared/core/ad-queue-item';
import { BaseComponent } from './shared/base-component';
import { AdVideoConfig } from './shared/configs/video-config';
import { Domains } from './configurations/domains';
import { PREBID_SETTINGS } from './configurations/prebid';
import {
    isVideoBlocked,
    setEventHandler,
    getDisplayAd,
    getScreenSize,
    userIsFromFbPromo,
    userIsFromAudigentFbPromo
} from './utils';
import { applyBackCompatibility } from './back-compability';
import { AdTagUrl } from './GoogleIMA/ad-tag-url';
import { IronSourceRewardVideo } from './reward/iron-source';
import { setupGdpr } from './gdpr';
import prebid from './prebid';
import { LIB_VERSION } from './version';
import { TestingAPI } from './internal/testing-api';

import { Analytics } from './analytics/';  // eslint-disable-line

import { GoogleH5 } from './shared/services/h5.ts';
import abTestManager from './shared/services/abTestManager';

const CTA_CLICK_TARGET = 'cta-click-target';

const dlog = debug('arkadium-ads:video:component');

// /* eslint-disable */
// if (!global._babelPolyfill && !window._babelPolyfill) {
//     require('@babel/polyfill');
// }
// /* eslint-enable */

prebid.processQueue();

try {
    window.localStorage.setItem('video-ad-version', LIB_VERSION);
} catch (e) {
    console.error(e);
}

type TradeDeferred = {
    promise: any;
    resolve: any;
}

export class VideoAdComponent extends BaseComponent {
    newCTATemplate: string;

    player: any;

    isPlaying = false;

    rewardVideo = null;

    aspectRatioKeeperSize: Object = { w: 0, h: 0 }

    tradeResolve: any;

    tradeDeferred: TradeDeferred = {
        promise: undefined,
        resolve: undefined
    }

    gdprConsentPromise: Promise<any>;

    videoPlayerTemplate = '<akr-ad-video-player id="ark-ad-video-player"></akr-ad-video-player>';

    tapi: TestingAPI;

    constructor() {
        super();
        dlog('[constructor]');
        // this.pubmaticIdHubPromise = this.pubmaticProvider.init();

        const isIframeGame = window.location.pathname.includes('mahjongg-solitaire')
        || window.location.pathname.includes('free-8-ball-pool')
        || window.location.pathname.includes('lumeno')
        || window.location.pathname.includes('jewel-shuffle')
        || window.location.pathname.includes('block-champ')
        || window.location.pathname.includes('family-feud')
        || window.location.pathname.includes('word-wipe')
        || window.location.pathname.includes('outspell')
        || window.location.pathname.includes('memory-game');

        if (window.location.hostname === 'www.arkadium.com'
            && isIframeGame
            && (userIsFromFbPromo() || userIsFromAudigentFbPromo())
            && (window.innerWidth - document.getElementById('preroll-container').clientWidth >= 400)) {
            dlog('[constructor] init rise outstream');
            loadScript('https://sdk.streamrail.com/wrapper/hb.loader.js?wrapper_id=61503de49bbbfe00016ebc3e&org=60d9ab656a43ad00010a56ed');
        }

        this.adQueueItem.adConfig = new AdVideoConfig();
        this.adQueueItem.adConfig.$element = this;
        this.adQueueItem.type = AdType.VIDEO;
        this.adQueueItem.componentId = this.componentId;
        // this.addPrebid(AdType.VIDEO, prebid,
        //     PREBID_SETTINGS, PREBID_CONFIG);
        /* $FlowFixMe */
        // this.coreService._requestsProcessor._prebids.get(AdType.VIDEO).BID_TIMEOUT = 2000;
        this.initTradeDeferred();
        this.tapi = new TestingAPI(prebid, Domains, this.adQueueItem.adConfig);
    }

    createdCallback() {
        dlog('[createdCallback]');
    }

    _isGDPREnabled(): boolean {
        return !!(this.adQueueItem.adConfig.gdpr && this.adQueueItem.adConfig.gdpr.enabled);
    }

    initTradeDeferred() {
        this.tradeDeferred.promise = new Promise((resolve) => {
            this.tradeDeferred.resolve = resolve;
        });
    }

    onVideoTradeFinished(items: string[]) {
        dlog(`[onVideoTradeFinished] for items = ${String(items)}`);
        this.tradeDeferred.resolve();
    }

    tradePromise(): Promise<any> {
        return new Promise((resolve) => {
            this.tradeResolve = resolve;
        });
    }

    async _createAdUnit(): Promise<any> {
        let adUnit;

        try {
            adUnit = window.localStorage.getItem('ark-ad-unit');
        } catch (e) {
            console.error(e); // eslint-disable-line
        }

        if (adUnit) {
            this.adQueueItem.adConfig.adUnit = adUnit;
        }

        const [videoAdsBlob, tcData] = await Promise.all([
            this.coreService.videoAdsBlobPromise,
            this.coreService.gdprConsentPromise,
        ]);

        const adsBlobJSON = await videoAdsBlob.clone().json();

        // can i just add prebid in here?
        const prebidConfig: any = {
            bidderTimeout: 3000,
            enableSendAllBids: false,
            priceGranularity: {
                buckets: [{
                    precision: 2,
                    max: 50,
                    increment: 0.01,
                }],
            },
            cache: {
                url: 'https://prebid.adnxs.com/pbc/v1/cache'
            },
            consentManagement: {
                usp: {
                    cmpApi: 'iab',
                    timeout: 8000, // US Privacy timeout 100ms
                },
            },
            useBidCache: true,
            userSync: {
                syncEnabled: true,
                syncDelay: 5000,
                syncsPerBidder: 50,
                pixelEnabled: true,
                iframeEnabled: true,
                filterSettings: {
                    iframe: {
                        bidders: '*',
                        filter: 'include',
                    },
                },
                userIds: [{
                    name: 'criteo',
                },
                {
                    name: 'uid2',
                },
                {
                    name: 'deepintentId',
                    storage: {
                        type: 'html5',
                        name: '_dpes_id',
                    },
                },
                {
                    name: 'pubCommonId',
                    storage: {
                        type: 'cookie',
                        name: 'ark_pubCommonId',
                        expires: 60,
                    },
                }, {
                    name: 'unifiedId',
                    params: {
                        url: '//match.adsrvr.org/track/rid?ttd_pid=71nw1m8&fmt=json',
                    },
                    storage: {
                        type: 'cookie',
                        name: 'ark_unifiedId',
                        expires: 60,
                    },
                }],
            },
            yahoossp: {
                mode: 'video',
                endpoint: 'https://c2shb.ssp.yahoo.com/bidRequest',
            },
        };

        // ab test for price floors
        if (!abTestManager.adsABTestValue) {
            // init
            const splitValue = Math.random();
            if (splitValue > 0.5) {
                abTestManager.setAdsABTestValue('prebid-floors-control');
            } else {
                abTestManager.setAdsABTestValue('prebid-floors-exposed');
            }
        }

        if (abTestManager.adsABTestValue === 'prebid-floors-exposed') {
            prebidConfig.floors = {
                default: 0.50,
            };
        }
        // end ab test for price floors

        if (tcData && tcData.gdprApplies) {
            prebidConfig.consentManagement.gdpr = {
                cmpApi: 'iab',
                timeout: 120000,
                defaultGdprScope: false,
            };
        }

        // Google MCM - means non-OO
        if (adsBlobJSON.mcmId) {
            this.adQueueItem.adConfig.dfpNetworkCode += `,${adsBlobJSON.mcmId}`;

            /* eslint-disable */
            const mcmIdSchainMap = {
                '1149516': 'ark-396',
                '322166814': 'ark-385',
                '142990740': 'ark-0007',
                '22676037499': 'ark-381',
                '61381659': 'ark-403',
                '2675387130': 'ark-0336',
                '22675954147': 'ark-0318',
                '22675948567': 'ark-0120',
                '424397508': 'ark-0081',
                '11222444': 'ark-0361',
                '22676082369': 'ark-0365',
                '21755365683': 'ark-405',
                '22676119157': 'ark-0128',
                '24467070': 'ark-404',
                '22657428805': 'ark-0004',
                '22647681405': 'ark-0015',
                '1004232': 'ark-0164',
                '128139881': 'ark-0105',
                '6119': 'ark-406',
                '7675': 'ark-0131',
                '22371676909': 'ark-0005',
                '6423': 'ark-0172',
                '22661991101': 'ark-0010',
                '1023153': 'ark-0246',
                '22676086668': 'ark-0244',
                '22367910145': 'ark-0114',
                '22328928714': 'ark-0379',
                '6178': 'ark-0011',
                '89799359': 'ark-0216',
                '1048906': 'ark-0197',
                '3893995': 'ark-0208',
                '94238257': 'ark-410',
                '162231487': 'ark-411',
                '7932': 'ark-390',
                '185186999': 'ark-0123',
                '58580620': 'ark-394',
                '22604766966': 'ark-0012',
                '22676129171': 'ark-0294',
                '22676618073': 'ark-0266',
                '701': 'ark-392',
                '7089059': 'ark-0006',
                '21716056466': 'ark-409',
                '22669329359': 'ark-0082',
                '22386192814': 'ark-0376',
                '3828090': 'ark-0334',
                '10483357': 'ark-387',
                '22358498799': 'ark-0112',
                '22387132673': 'ark-0245',
                '151404300': 'ark-395',
                '22675953403': 'ark-391',
            };
            /* eslint-enable */

            if (mcmIdSchainMap[adsBlobJSON.mcmId]) {
                prebidConfig.schain = {
                    config: {
                        ver: '1.0',
                        complete: 1,
                        nodes: [{
                            asi: 'arkadium.com',
                            sid: mcmIdSchainMap[adsBlobJSON.mcmId],
                            hp: 1,
                        }],
                    },
                };
            }
        }

        this.addPrebid(AdType.VIDEO, prebid, PREBID_SETTINGS, prebidConfig);

        this.adQueueItem.adUnit.code = this.realComponentId;
        this.adQueueItem.adConfig.dimensions = this.adQueueItem.adConfig.dimensions || [[640, 480]];
        this.adQueueItem.adUnit.sizes = this.adQueueItem.adConfig.dimensions;
        this.adQueueItem.adUnit.bids = this.getBidsForSlotSize(adsBlobJSON.bids);
        if (window.location.hostname === 'www.gamelab.com'
            || window.location.hostname === 'games.dailymail.co.uk') {
            this.adQueueItem.adUnit.bids.push({ bidder: 'sovrn', params: { tagid: '997385' } });
        }
        this.adQueueItem.adUnit.mediaTypes = {
            video: {
                pos: 1,
                context: 'instream',
                placement: 1,
                playerSize: [640, 480],
                linearity: 1,
                maxduration: 60,
                skip: 1,
                skipmin: 31,
                api: [1, 2],
                protocols: [1, 2, 3, 4, 5, 6, 7, 8],
                playbackmethod: [3],
                mimes: [
                    'application/javascript',
                    'video/mp4',
                ],
            }
        };

        this.adQueueItem.adConfig.adParamsFn = AdTagUrl.dfpVideoParams.bind(AdTagUrl);
    }

    getBidsForSlotSize(inputBids: any) {
        const allSlotBids = [];
        this.adQueueItem.adConfig.dimensions.forEach((dimension) => {
            const videoSizeKey = `v${dimension[0]}x${dimension[1]}`;
            const elementId = this.adQueueItem.adUnit.code;

            let allSizeBids = inputBids[videoSizeKey].default;
            if (inputBids[videoSizeKey][elementId]) {
                const biddersToFilterOut = [];
                inputBids[videoSizeKey][elementId].forEach((bid) => {
                    if (biddersToFilterOut.indexOf(bid.bidder) === -1) biddersToFilterOut.push(bid.bidder);
                });

                allSizeBids = allSizeBids.filter(bid => biddersToFilterOut.indexOf(bid.bidder) === -1);
                allSizeBids = allSizeBids.concat(inputBids[videoSizeKey][elementId]);
            }

            allSizeBids.forEach((sizeBid) => {
                if (!allSlotBids.find(slotBid => slotBid.id === sizeBid.id)) allSlotBids.push(sizeBid);
            });
        });

        return allSlotBids;
    }

    refresh() { }

    onPlayClick = () => {
        this.isPlaying = true;
        this.render();
        this._onResize();
        this.tradeDeferred.promise
            .then(() => this.onPlayerShow())
            .catch(e => this.fallback(e.message));
    }

    onPlayerShow() {
        const player: any = this.querySelector('#ark-ad-video-player');
        this.player = player;

        dlog(`[onPlayerShow] hasPlayer = ${String(!!this.player)}`);
        if (player) {
            if (this.adQueueItem.adConfig.retryOnError) player.setAdConfig(this.adQueueItem.adConfig);
            player.events.addEventListener('Error', ({ message, retry }) => {
                dlog(`[onPlayerShow] (Error) message = ${message} retry = ${retry}`);
                this.isPlaying = false;

                const gameName = window.location.pathname.split('/').length > 2 ? window.location.pathname.split('/') : undefined;
                Analytics.getInstance().error(message, { domain: window.location.hostname, game: gameName });
                if (retry && window.location.hostname !== 'games.insp.com') {
                    this.adQueueItem.adConfig.retryOnError = false;
                    this.adQueueItem.adConfig.autoPlay = false;
                    this.adQueueItem.adConfig.adUrl = AdTagUrl.generate(this.adQueueItem.adConfig);
                    this.adQueueItem.adConfig.adUrl = AdTagUrl.addCustomParam(this.adQueueItem.adConfig.adUrl, 'retryOnError', '1');
                    player.adsLoader.requestAds();
                } else {
                    dlog('[onPlayerShow] Error and no retries... Executing fallback');
                    this.fallback(message, player);
                }
            });
            player.addEventListener('videoCompleted', () => {
                dlog('[onPlayerShow] (videoCompleted)');
                this.dispatchEvent(new CustomEvent('videoCompleted', {
                    detail: {
                        hasReward: !!this.adQueueItem.adConfig.reward
                    }
                }));
            });

            this._onResize();
        } else {
            this.fallback('No player');
        }
    }

    tryUpdateMobile() {
        const el = this.querySelector(`#${this.realComponentId}`);

        if (el) {
            const isMobileMode = this.adQueueItem.adConfig.mobileFullScreen && isMobile();
            if (isMobileMode) {
                el.classList.add('mobile-player');
            } else {
                el.classList.remove('mobile-player');
            }
        }
    }

    _onResize = () => {
        this.tryUpdateMobile();
        /* $FlowFixMe */
        const ratioKeeper = this.querySelector('#ark-video-ratio-keeper');
        /* $FlowFixMe */
        const { width, height, addControlsSize } = getScreenSize(ratioKeeper.parentNode.clientWidth);

        if (ratioKeeper) {
            ratioKeeper.setAttribute('width', width);
            ratioKeeper.setAttribute('height', addControlsSize ? height + 30 : height);
        }

        const sizeHasChanged = (this.aspectRatioKeeperSize.w !== width)
            || (this.aspectRatioKeeperSize.h !== height);

        if (sizeHasChanged && ratioKeeper) {
            const { clientWidth, clientHeight } = ratioKeeper;
            if (this.player && this.player.adsLoader) {
                this.player.adsLoader.resize(clientWidth, clientHeight);
            }
            this.aspectRatioKeeperSize.w = clientWidth;
            this.aspectRatioKeeperSize.h = clientHeight;
        }
    }

    initBlockScreen(skip: boolean = false) {
        const button = this.getElementsByClassName(CTA_CLICK_TARGET)[0];
        if (button) {
            setEventHandler(button, 'click', this.onPlayClick);
            if (skip) {
                button.click();
            }
            button.style.cursor = 'pointer';
        }
    }

    showGoogleReward() {
        dlog('[connectedCallback] init - google reward web video');
        this.coreService.enableGTM();

        window.googletag.cmd.push(() => {
            const rewardedSlot = window.googletag.defineOutOfPageSlot('/100151972/erictestadunit',
                window.googletag.enums.OutOfPageFormat.REWARDED).addService(window.googletag.pubads());
            rewardedSlot.setForceSafeFrame(true);

            window.googletag.pubads().addEventListener('rewardedSlotReady', (evt) => {
                evt.makeRewardedVisible();
                dlog('[connectedCallback] google rewardedSlotReady');
            });

            window.googletag.pubads().addEventListener('rewardedSlotGranted', (evt) => {
                dlog('[connectedCallback] google reward GRANTED - evt: ', evt);
                this.dispatchEvent(new CustomEvent('videoCompleted'));
            });

            window.googletag.pubads().addEventListener('rewardedSlotClosed', (evt) => {
                dlog('[connectedCallback] google reward video CLOSED - evt: ', evt);
                this.dispatchEvent(new CustomEvent('videoCompleted'));
            });

            window.googletag.pubads().refresh([rewardedSlot]);
        });
    }

    showH5Reward() {
        dlog('[showH5Reward] - start');
        const h5 = new GoogleH5(this.componentId);
        const finish = () => this.dispatchCompleted();
        const showReward = Boolean(window.location.href.includes('h5Reward'));
        const render = () => {
            window.addEventListener('resize', this._onResize);
            super.connectedCallback();
            this._onResize();
            this.initBlockScreen();
        };
        if (showReward) {
            h5.reward(finish).then((showAd) => {
                if (showAd) {
                    dlog('[H5] has reward');
                    this.onPlayClick = showAd;
                } else {
                    dlog('[H5] doest have reward, showing interstitial');
                    this.onPlayClick = () => h5.next().then(finish);
                }
                render();
            });
        } else {
            this.onPlayClick = () => {
                const btn = document.querySelector('.ark-ad-button');
                if (btn) btn.innerHTML = 'Loading...';
                h5.next().then(finish);
            };
            render();
        }
    }

    connectedCallback() {
        this.setConfigFromAttributes();

        const { reward } = this.adQueueItem.adConfig;
        const isReward = this.adQueueItem.adConfig.type === 'reward';

        if (this.adQueueItem.adConfig.product === 'xml') {
            getParentTCF();
        }

        dlog(`[connectedCallback] isRewardVideo = ${String(!!reward)} isDesktopPlatform = ${!!(reward && reward.isDesktopPlatform)}`);
        const ironSourceEnabled = Boolean(reward && 'applicationKey' in reward && reward.applicationKey);
        const googleRewardEnabled = Boolean(reward && 'showGoogleReward' in reward && reward.showGoogleReward);
        const googleH5Enabled = Boolean((isReward
            && window.location.href.includes('games/word-wipe')
            && window.location.href.includes('arkadium.com'))
            || window.location.href.includes('h5Force=true'));
        if (ironSourceEnabled) {
            dlog(`[connectedCallback] showAsMidroll = ${String(reward.showAsMidroll)}`);
            if (!reward.showAsMidroll) {
                this.initIronSourceReward();
            } else {
                this.dispatchEvent(new CustomEvent('reward-ready', {}));
            }
        } else if (googleRewardEnabled) {
            this.showGoogleReward();
        } else if (googleH5Enabled) {
            this.showH5Reward();
        } else {
            this.initBaseVideo();
        }
    }

    initIronSourceReward() {
        const { reward } = this.adQueueItem.adConfig;
        this.rewardVideo = new IronSourceRewardVideo();
        this.rewardVideo.init({
            applicationKey: reward.applicationKey,
            rewardId: reward.rewardId,
        }).then(() => {
            this.dispatchEvent(new CustomEvent('reward-ready', {}));
        }).catch((err) => {
            this.dispatchEvent(new CustomEvent('AdError', {
                detail: {
                    message: err.message
                }
            }));

            this.dispatchEvent(new CustomEvent('videoCompleted', {
                detail: {
                    hasReward: false
                }
            }));
        });
    }

    setConfigFromAttributes() {
        const attrs = {};
        for (let i = this.attributes.length - 1; i >= 0; i--) {
            if (this.attributes[i].name !== 'options') {
                attrs[this.attributes[i].name] = getAttributeValue(this.attributes[i].value);
            }
        }

        const options = getAttributeValue(this.getAttribute('options')) || {};

        this.adQueueItem.adConfig.fromJSON({ ...options, ...attrs });
    }

    initBaseVideo() {
        this._createAdUnit()
            .then(() => {
                dlog('[connectedCallback] check if is video blocked && check if auction finished');
                this.coreService.onVideoTradeFinished = items => this.onVideoTradeFinished(items);
                const { autoPlay, mute, type } = this.adQueueItem.adConfig;
                const isReward = type === 'reward';
                // Check for video blocking only for autoPlay = true
                const continuation = autoPlay ? isVideoBlocked(mute, false, isReward) : Promise.resolve(false);

                continuation.then((blocked) => {
                    this.isPlaying = !blocked && autoPlay;
                    dlog(`[connectedCallback] video blocked = ${String(!!blocked)} autoPlay = ${String(this.adQueueItem.adConfig.autoPlay)}`);
                    dlog(`[connectedCallback] is video playing = ${String(this.isPlaying)}`);
                    if (this._isGDPREnabled()) {
                        setupGdpr(prebid, !!this.adQueueItem.adConfig.gdpr.personalizedAds);
                    }
                    super.connectedCallback();
                    this._onResize();
                    window.addEventListener('resize', this._onResize);

                    if (!this.isPlaying) {
                        this.initBlockScreen();
                    } else {
                        this.tradeDeferred.promise.then(() => {
                            this.onPlayerShow();
                        });
                    }
                });
            });
    }

    get customTagline(): string {
        return this.getConfig().CTAScreenConfig.customTagline || '';
    }

    get message(): string {
        return (
            this.adQueueItem.adConfig.CTAScreenConfig.messageText
            || 'your game will begin after the following advertisement'
        );
    }

    get playButtonCaption(): string {
        return (
            this.getConfig().CTAScreenConfig.buttonText
            || 'Play'
        );
    }

    get playButton(): string {
        return `
            <svg height="12" width="12" class = "video-play-button">
                <polygon points="0,0 0,12 10,6" style="fill:#fff" />
            </svg>
        `;
    }

    get realComponentId(): string {
        return this.externalComponentId || `component_${this.componentId}`;
    }

    getConfig(): AdVideoConfig {
        return this.adQueueItem.adConfig;
    }

    template(): string {
        const id = this.realComponentId;
        const {
            containerClass,
            buttonClass,
            messageBoxClass,
            buttonArrowClass,
            buttonTextClass,
            colLeftClass,
            colRightClass,
            customTagline,
        } = this.getConfig().CTAScreenConfig;

        this.dispatchEvent(new CustomEvent('ctaStateChange', { detail: { isShowing: !this.isPlaying } }));

        this.newCTATemplate = `
            <ark-ad-div class="ark-ad-inner ${CTA_CLICK_TARGET} ${containerClass || ''}">
                ${customTagline || ''}
                <ark-ad-div class="ark-ad-cta-col-left ${colLeftClass || ''}"></ark-ad-div>
                <ark-ad-div class="ark-ad-cta-col-right ${colRightClass || ''}">
                    <ark-ad-div class="ark-ad-message ${messageBoxClass || ''}">
                        ${this.message}
                    </ark-ad-div>

                    <button class="ark-ad-button ${buttonClass || ''}" data-type = "play-button" type="button">
                        <ark-ad-div class="ark-ad-button-text ${buttonTextClass || ''}">
                            ${this.playButtonCaption}
                        </ark-ad-div>
                        <ark-ad-div class="ark-ad-button-arrow ${buttonArrowClass || ''}">
                            ${this.playButton}
                        </ark-ad-div>
                    </button>
                </ark-ad-div>
            </ark-ad-div>
        `;


        return `
            <ark-ad-div class="ark-ad-main" id = "${id}">
                <canvas class = "ratio-keeper" id = "ark-video-ratio-keeper"></canvas>
                ${this.isPlaying ? this.videoPlayerTemplate : this.newCTATemplate}
            </ark-ad-div>
        `;
    }

    disconnectedCallback() {
        window.removeEventListener('resize', this._onResize);
        super.disconnectedCallback();
    }

    // this needs display-ads library to handle base bids component properly to include all included sizes
    // getAllowableAdSizes(parentHeight: number, parentWidth: number) {
    //     // TODO once we implement JARVIS,
    //     // this is going to get improved where it will include ALL possible ad sizes
    //     // not just ones that are preset by the basebidscomponent thing

    //     if (parentWidth >= 990 && parentHeight >= 630) {
    // eslint-disable-next-line
    //         return '[[970, 250], [970, 90], [728, 90], [336, 280], [300, 600], [300, 250], [250, 250], [320, 50], [320, 100], [160, 600]]'; // eslint-disable-line max-len
    //     }
    //     if (parentWidth > 350 && parentHeight >= 630) {
    //         return '[[300, 600], [160, 600], [300, 250], [336, 280], [250, 250], [320, 50], [320, 100]]';
    //     }

    //     return '[[300, 250], [250, 250], [320, 50], [320, 100]]';
    // }

    fallback(message: string, player?: any) {
        const timeout = setTimeout(() => this.dispatchCompleted(), 5000);
        if (!getDisplayAd()) {
            dlog('[fallback] No display-ad-component');
            if (player) {
                clearTimeout(timeout);
                player.complete();
            }
            return;
        }

        const maxGameWidth = this.aspectRatioKeeperSize.w;
        const maxGameHeight = this.aspectRatioKeeperSize.h;

        dlog('[fallback] Rendering display-ad-component');
        const display = `
        <ark-ad-div class="ark-ad-display-container">
            <display-ad-component data-id="ark-video-fallback" product="ads-video" dimensions=${JSON.stringify(this.determineFallbackDimensions(maxGameWidth, maxGameHeight))} style="min-width: 300px; min-height: 250px;"></display-ad-component>
            <ark-ad-div class="ark-ad-end-message">The game will start soon...</ark-ad-div>
        </ark-ad-div>
        `;
        this.removePlayer(player);
        const main = this.querySelector('.ark-ad-main');
        if (main) {
            main.insertAdjacentHTML('beforeend', display);
        }
        this.startCountdown();
        this._onResize();
        clearTimeout(timeout);
    }

    determineFallbackDimensions(maxWidth: number, maxHeight: number): Array<Array<number>> {
        const allSizes = [
            [970, 250],
            [970, 90],
            [728, 90],
            [336, 280],
            [320, 100],
            [320, 50],
            [300, 600],
            [300, 250],
            [250, 250],
            [160, 600]
        ];

        return allSizes.filter(elem => elem[0] < maxWidth && elem[1] < maxHeight);
    }

    startCountdown() {
        const msg = this.querySelector('.ark-ad-end-message');
        let interval;
        let seconds = Number(this.adQueueItem.adConfig.fallbackDuration || 8);
        const update = () => {
            if (seconds > 0 && msg) {
                seconds--;
                msg.innerText = `The game will start in ${seconds.toFixed(0)}...`;
            } else {
                clearInterval(interval);
                this.dispatchCompleted();
            }
        };
        interval = setInterval(update, 1000);
    }

    dispatchCompleted() {
        this.dispatchEvent(new CustomEvent('videoCompleted', {
            detail: {
                hasReward: !!this.adQueueItem.adConfig.reward
            }
        }));
    }

    removePlayer(player?: HTMLElement) {
        dlog(`[removePlayer] player = ${String(!!player)}`);
        if (player && player.parentNode) {
            player.parentNode.removeChild(player);
        }
    }
}

if (!customElements.get('video-ad-component')) {
    customElements.define('video-ad-component', VideoAdComponent);
}

applyBackCompatibility();
