// @flow

export class TestingAPI {
    prebid: any = null;

    domains: any = null;

    config = {};

    auction = {};

    receivedBids = [];

    constructor(prebid: any, domains: any, config: any) {
        this.domains = domains;
        this.prebid = prebid;
        this.config = config;
        this._bindEventHandlers();
    }

    getPrebidVersion() {
        return this.prebid.version;
    }

    getAllBidders() {
        return this.domains.getAllBidders();
    }

    getBidRequests() {
        return this.auction.bidderRequests;
    }

    getBidResponses() {
        return this.auction.bidsReceived;
    }

    _bindEventHandlers() {
        this.prebid.onEvent('auctionEnd', this._auctionEndHandler.bind(this));
    }

    _auctionEndHandler(auction: any) {
        this.auction = auction;
    }
}
