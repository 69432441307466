// @flow

export function getHostDomain(url: string = ''): string {
    if (typeof url === 'string' && url !== '') {
        return url;
    }

    return window.location.host.toLowerCase();
}

export function getPagePath(): string {
    let path = '';
    try {
        path = window.parent.document.location.pathname.toLowerCase();
    } catch (e) {
        path = document.location.pathname.toLowerCase();
    }

    return path;
}

export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
}

export function getTopDomain(url: string): string {
    if (typeof url === 'string' && url !== '') return url;
    const hosts = window.location.host.toLowerCase().split('.');
    return `${((hosts.length > 3 && hosts[hosts.length - 1].length < 3) ? `${hosts[hosts.length - 3]}.` : '') + hosts[hosts.length - 2]}.${hosts[hosts.length - 1]}`;
}

export function getBidsDomain(url: string): string {
    if (typeof url === 'string' && url !== '') return url;
    return window.location.host.toLowerCase();
}

export function queryStringsExist() {
    return window.location.search.indexOf('&') !== -1 && window.location.search.indexOf('=') !== -1;
}

export function getQueryStrings() {
    const queryStringMap = {};
    const allQueryStrings = decodeURIComponent(window.location.search).slice(1).split('&');
    allQueryStrings.forEach((queryString) => {
        const queryStringKey = queryString.split('=')[0];
        const queryStringValue = queryString.split('=')[1];

        queryStringMap[queryStringKey] = queryStringValue;
    });

    return queryStringMap;
}

export function isWebView() {
    // client side detection works for IOS devices only, for Android devices server side solution needed
    const { standalone } = window.navigator;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    return ios && !standalone && !safari;
}

export function loadScript(url : string): Promise<string> {
    return new Promise((resolve, reject) => {
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        if (script.readyState) { // IE
            script.onreadystatechange = () => {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    resolve(url);
                }
            };
        } else { // Others
            script.onload = () => resolve(url);
            script.onerror = err => reject(err);
        }

        script.src = url;
        const { head }: any = document;
        head.appendChild(script);
    });
}

export function getAttributeValue(attribute: ?string = ''): any {
    if (!attribute || attribute === '' || attribute === 'undefined') {
        return undefined;
    }

    let value;
    try {
        value = JSON.parse(attribute);
    } catch (e) {
        value = attribute;
    }

    return value;
}

export function getArenaInfo(window: any) {
    const info = window.__ARK__ArenaInfo;

    if (info && info.arenaVersion === '4') {
        return {
            arena_version: '4',
            arena_name: `${info.siteBaseUrl}`,
            arena_isSub: `${!!info.isSub}`,
            arena_key: `${info.arenaKey}`,
        };
    }

    if (info && info.arenaVersion === '5.1') {
        return {
            arena_version: '5.1',
            arena_name: `${info.domain}`,
            arena_isSub: 'false',
            arena_key: '',
        };
    }

    return {
        arena_version: 'null',
        arena_name: `${window.location.origin}`,
        arena_isSub: 'false',
        arena_key: 'null',
    };
}

export function getParentTCF() {
    (function () {
        // start here at our window
        let frame = window;

        // if we locate the CMP iframe we will reference it with this
        let cmpFrame;

        // map of calls
        const cmpCallbacks = {};

        while (frame) {
            try {
            /**
             * throws a reference error if no frames exist
             */

                if (frame.frames.__tcfapiLocator) {
                    cmpFrame = frame;
                    break;
                }
            } catch (ignore) {} // eslint-disable-line no-empty

            if (frame === window.top) {
                break;
            }

            frame = frame.parent;
        }

        /**
        * Set up a __tcfapi proxy method to do the postMessage and map the callback.
        * From the caller's perspective, this function behaves identically to the
        * CMP API's __tcfapi call
        */

        window.__tcfapi = function (cmd, version, callback, arg) {
            if (!cmpFrame) {
                callback({ msg: 'CMP not found' }, false);
            } else {
                const callId = `${Math.random()}`;
                const msg = {
                    __tcfapiCall: {
                        command: cmd,
                        parameter: arg,
                        version,
                        callId,
                    },
                };

                /**
                * map the callback for lookup on response
                */

                cmpCallbacks[callId] = callback;
                cmpFrame.postMessage(msg, '*');
            }
        };

        function postMessageHandler(event) {
            /**
          * when we get the return message, call the mapped callback
          */

            let json = {};

            try {
            /**
              * if this isn't valid JSON then this will throw an error
              */
                json = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
            } catch (ignore) {} // eslint-disable-line no-empty

            const payload = json.__tcfapiReturn;

            if (payload) {
            /**
              * messages we care about will have a payload
              */
                if (typeof cmpCallbacks[payload.callId] === 'function') {
                /**
               * call the mapped callback and then remove the reference
               */
                    cmpCallbacks[payload.callId](payload.returnValue, payload.success);
                    cmpCallbacks[payload.callId] = null;
                }
            }
        }

        window.addEventListener('message', postMessageHandler, false);
    }());
}

export function getCookieValue(inputCookieName: string) {
    if (document.cookie.indexOf(inputCookieName) === -1) return undefined;
    let cookieValue = '';
    document.cookie.split('; ').forEach((cookieLine) => {
        if (cookieLine.indexOf(inputCookieName) !== -1) {
            cookieValue = cookieLine.split('=')[1]; // eslint-disable-line prefer-destructuring
        }
    });

    return cookieValue;
}
