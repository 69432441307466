// @flow

import debug from '../../debug';
import { AdDisplayConfig } from '../../configs/display-config';
import {
    loadScript, isWebView, getPagePath, getHostDomain,
} from '../../utils';
import { AdUnit } from '../units';

const dlog = debug('arkadium-ads:providers:glade');

export class GladeProvider {
    constructor() {
        this.init();
    }

    bids: Object[] = [];

    targetings: Object[] = [];

    renderPrebidCallback: (any, string) => any;

    renderAmazonCallback: (any, string) => any;

    gladeId: string = `glade-id${Math.floor(4503599627370496 * Math.random())}`;

    init(): Promise<any> {
        if (window.glade && window.glade.run) {
            return Promise.resolve();
        }

        window.addEventListener('message', this.onPbJsWon);

        return loadScript('//securepubads.g.doubleclick.net/static/glade.js');
    }

    setBidsInfo(bids: Object[]) {
        this.bids = bids;
    }

    renderAmazon(element: HTMLElement, adId: string) {
        if (this.renderAmazonCallback) {
            dlog('[renderAmazon]');
            this.renderAmazonCallback(element, adId);
        }
    }

    renderPrebid(element: HTMLElement, adId: string) {
        if (this.renderPrebidCallback) {
            dlog('[renderPrebid]');
            this.renderPrebidCallback(element, adId);
        }
    }

    onPbJsWon = (event: any) => {
        // if event has 'ark_hb_' this would indicate on call from DFP ad creative
        if (event.data && event.data.split && event.data.split('ark_hb_').length > 1) {
            dlog(`[onPbJsWon] get message from dfp ${event.data}`);
            const keyString = event.data.substr(0, event.data.indexOf(':'));
            const unitId = event.data.split(`${keyString}:`)[1];
            const iframeElement = this.createIframe(unitId, keyString);
            if (keyString === 'ark_hb_a9') {
                /* $FlowFixMe */
                this.renderAmazon(iframeElement.contentWindow.document, unitId);
            } else if (keyString === 'ark_hb_page') {
                /* $FlowFixMe */
                this.renderPrebid(iframeElement.contentWindow.document, unitId);
            }
        }
    }

    createIframe(adId: string, key: string): ?HTMLElement {
        const propName = key === 'ark_hb_page' ? 'hb_adid' : 'amzniid';
        const targeting = this.targetings.find(i => i[propName] === adId);
        if (!targeting) return null;
        /* $FlowFixMe */
        const target = document.getElementById(targeting.slotID);
        if (target) {
            const iframe = document.createElement('iframe');
            /* $FlowFixMe */
            const size = targeting.__size; // select first dimension (if more than 1)
            /*eslint-disable */
            iframe.width = size[0];
            iframe.height = size[1];
            /* eslint-enable */
            iframe.scrolling = 'no';
            iframe.marginWidth = '0px';
            iframe.marginHeight = '0px';
            /* $FlowFixMe */
            iframe.frameborder = '0px';
            const div = target.querySelector('div');
            if (div) {
                div.setAttribute('style', 'width:0px; height:0px');
            }
            target.insertBefore(iframe, div); // attach our new iframe now to DOM
            return iframe;
        }

        return null;
    }

    createAdContainer(id: string, size: number[], unitPath: string, data: string) {
        const container = document.createElement('div');
        container.setAttribute('id', this.gladeId);
        container.setAttribute('data-glade', '');
        container.setAttribute('data-ad-unit-path', unitPath);
        container.setAttribute('width', size[0].toString());
        container.setAttribute('height', size[1].toString());
        container.setAttribute('data-json', data);
        return container;
    }

    render(targetingOptions: Object = {}, { code }: AdUnit, adConfig: AdDisplayConfig) {
        const bid = this.bids.find(item => item.slotID === code) || {};
        const targeting = { ...targetingOptions, ...bid };
        targeting.dfpPrebid = '2';
        if (adConfig.trackElementID) targeting.elementId = code;
        if (adConfig.trackPagePath) targeting.pagePath = getPagePath(); // optional page path
        if (adConfig.trackWebView && isWebView()) targeting.webView = '1'; // optional tracking on webView
        if (adConfig.product) targeting.product = adConfig.product; // optional Arkadium product
        (adConfig.keyValues || []).forEach(([k, v]) => { targeting[k] = v; });

        const unitPath = `/${adConfig.dfpNetworkCode}/${getHostDomain(adConfig.adUnit)}`;
        this.targetings.push(targeting);
        const targetingData = JSON.stringify({ targeting });
        /*eslint-disable*/
        targeting.__size = adConfig.dimensions[0];
        /* eslint-enable */
        const adContainer = this.createAdContainer(this.gladeId, adConfig.dimensions[0], unitPath, targetingData);
        const container = document.getElementById(code);
        if (container) {
            container.innerHTML = '';
            container.appendChild(adContainer);
        }

        adContainer.addEventListener('gladeAdRendered', () => {
            dlog('[render] gladeAdRendered');
            // utils.debugMsg('rendered:' + key);
            // this.onDisplayAdVisible(key);
        });
        // window.glade.correlator = Math.floor(4503599627370496 * Math.random())
        dlog('[render] google');
        if (window.glade && window.glade.run) {
            window.glade.run();
        }
    }
}
