// @flow

import { AdConfigBase } from './base';

export class AdDisplayConfig extends AdConfigBase {
    target: string = '';

    dimensions: number[][] = [];

    product: string = '';

    refreshTime: number = 0;

    adUnit: string = '';

    dfpNetworkCode: string = '100151972';

    viewableOnly: boolean = true ;

    gaTracker: ?string = undefined;

    trackPagePath: boolean = false;

    trackElementID: boolean = true;

    trackWebView: boolean = false;

    viewabilityPercent: number = 25;

    headerBidding: boolean = true;

    kbaEnabled: boolean = true;

    keyValues: any[] = [];
}
