// @flow
/* eslint-env browser */

import { getCookieValue } from '../utils';
import debug from '../debug';

export const GEO_KEY = 'arkadiumGeo';
export const CMP_TIMEOUT = 30000;

const shouldWaitOT = () => !!document.querySelector('script[src$="otSDKStub.js"]');
const shouldWaitDidomi = () => !!document.querySelector('script[src$="ep00.epimg.net/js/comun/avisopcdidomi.js"]');

const GDPR_COUNTRIES = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT',
    'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'IS', 'NO',
];

function errorRaiser(message: string) {
    return function raiseError() {
        throw new Error(message);
    };
}


const dlog = debug('arkadium-ads:CMPService');

// See here for explanation:
// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#tcdata
export type TCData = {
    tcString: string,
    tcfPolicyVersion: 2,
    cmpId: ?Number,
    cmpVersion: ?Number,
    gdprApplies: ?Boolean,
    eventStatus: String,
    cmpStatus: 'string',
    listenerId: ?Number,
    isServiceSpecific: Boolean,
    useNonStandardStacks: Boolean,
    publisherCC: string,
    purposeOneTreatment: Boolean,
    purpose: {
        consents: any,
        legitimateInterests: any,
    },
    vendor: {
        consents: ?any,
        legitimateInterests: ?any,
    },
    specialFeatureOptins: ?any,
    publisher: {
        consents: ?any,
        legitimateInterests: ?any,
    }
};

export class CMPService {
    _geo: ?string;

    _tcData: ?TCData;

    _tcDataPromise: Promise<?TCData>;

    /**
     * Get country code from cookie or API
     *
     * @returns
     * @memberof CMPService
     */
    async getGeo() {
        dlog(`[getGeo] _geo is ${String(this._geo)}`);

        if (!this._geo) {
            this._geo = this._getFromCookie();
            dlog(`[_getFromCookie] _geo is ${String(this._geo)}`);
        }

        if (!this._geo) {
            this._geo = await this._getFromAPI();
            dlog(`[_getFromAPI] _geo is ${this._geo}`);
            this._persistGeo();
        }

        return this._geo;
    }

    /**
     * Resolves to true if user's country code is specified under GDPR_COUNTRIES
     *
     * @returns Promise<boolean>
     * @memberof CMPService
     */
    async doesUserNeedGDPR() {
        return GDPR_COUNTRIES.includes(await this.getGeo()) && (shouldWaitOT() || shouldWaitDidomi());
    }

    /**
     * Wait for __tfcapi if current counry code requires GDPR and return it,
     * Otherwise return null
     *
     * @returns Promise<TCFAPI | null>
     * @memberof CMPService
     */
    async getCMP() {
        dlog(`[getCMP] _geo is ${String(this._geo)}, time is ${performance.now()}`);
        const timeout = setTimeout(errorRaiser('Timeout while waiting for CMP'), CMP_TIMEOUT);
        await this._waitForCMP();
        clearTimeout(timeout);
        dlog(`[getCMP] _geo is ${String(this._geo)}, time is ${performance.now()}, __tfcapi is ${!!window.__tcfapi}`);
        return window.__tcfapi;
    }

    /**
     * Wait for TCData from CMP and return it
     * Returns null if current country does not need GDPR
     *
     * @returns
     * @memberof CMPService
     */
    async getTCData() {
        if (this._tcData) {
            dlog(`[getTCData] _tcData is ${String(!!this._tcData)}`);
            return this._tcData;
        }

        if (this._tcDataPromise) {
            dlog(`[getTCData] _tcDataPromise is ${String(!!this._tcDataPromise)}`);
            return this._tcDataPromise;
        }

        if (!(await this.doesUserNeedGDPR())) {
            dlog(`[getTCData] ${String(this._geo)} user does not need tcData`);
            return null;
        }

        return this._waitForCMP()
            .then(() => { // eslint-disable-line arrow-body-style
                /* eslint-disable no-return-assign */
                return this._tcDataPromise = new Promise((res) => {
                    const checkTCDataInterval = setInterval(() => {
                        window.__tcfapi('getTCData', 2, (e: TCData, success) => {
                            if (e) {
                                // According to this example: https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#addeventlistener
                                // Google Funding Choices does not have e.eventStatus on CMP load
                                // $FlowFixMe
                                const isLoaded = e.cmpId === 300 ? !!e.tcString : success && ['tcloaded', 'useractioncomplete'].includes(e.eventStatus);
                                if (isLoaded) {
                                    dlog(`[getTCData] eventStatus = ${String(e && e.eventStatus)}, success = ${success}`);
                                    this._tcData = e;
                                    window.__tcfapi('removeEventListener', 2, () => {}, e.listenerId);
                                    clearInterval(checkTCDataInterval);
                                    res(e);
                                }
                            }
                        });
                    }, 100);
                });
                /* eslint-enable no-return-assign */
            });
    }

    _getFromCookie() {
        return getCookieValue(GEO_KEY);
    }

    _getFromAPI() {
        const opts = { headers: { 'X-GEO-APP': 'ArkadiumAds' } };
        return fetch('https://geoip.cdn.arkadiumhosted.com/json/', opts)
            .then(response => response.json())
            .then(responseJSON => responseJSON.country_code);
    }

    _persistGeo() {
        if (!this._geo) {
            return;
        }

        try {
            document.cookie = `${GEO_KEY}=${this._geo}; path=/; max-age=604800; samesite=lax`;
            dlog(`[_persistGeo] _geo is ${this._geo}`);
        } catch (e) {
            // TODO: Add AppInsights tracking
            dlog(`[_persistGeo] error! ${e.message}`, e.stack);
        }
    }

    _waitForCMP() {
        const CHECK_INTERVAL = 100;

        return new Promise<void>((resolve) => {
            const interval = setInterval(() => window.__tcfapi && resolve(clearInterval(interval)), CHECK_INTERVAL);
        });
    }
}
