// @flow

import debug from '../shared/debug';
import HTMLElementBase from '../shared/base-component';
import GoogleIMA from '../GoogleIMA/google-ima';

import { AdsLoader } from '../GoogleIMA/ads-loader';
import { setEventHandler } from '../utils';
import { EventEmitter } from '../events';

import './skip-button';
import './style.css';

const dlog = debug('arkadium-ads:video:player');

/* $FlowFixMe */
export default class Player extends HTMLElementBase {
    isVideoLoaded: boolean = true;

    isVideoPlaying: boolean = true;

    isVideoCompleted: boolean = false;

    isSoundOn: boolean = true;

    adConfig: Object;

    adsLoader: AdsLoader;

    adDuration: number = 0;

    adRemainingTime: number = 0;

    events: EventEmitter = new EventEmitter();

    resizeFn: any;

    adErrorTimer: TimeoutID;

    impressionFired: boolean = false;

    impressionFiredTime: Date;

    retryOnError: boolean = false;

    initImaSettings() {
        if (this.adConfig.vpaidInsecure) {
            GoogleIMA.settings.setVpaidMode(GoogleIMA.ImaSdkSettings.VpaidMode.INSECURE);
        }

        GoogleIMA.settings.setNumRedirects(8);
    }

    initAdsLoader() {
        // Really, fuck FlowType
        // $FlowFixMe
        const looseyGoosey: HTMLElement = this.parentNode;
        const container = looseyGoosey.querySelector('#ark_video_container');
        const keeper = looseyGoosey.querySelector('#ark-video-ratio-keeper');
        this.adsLoader = new AdsLoader(this.adConfig, container, keeper);

        AdsLoader.EVENTS.forEach(event => (
            setEventHandler(this.adsLoader.events, event, this[event])
        ));

        this.adsLoader.requestAds();
        if (this.resizeFn) {
            this.resizeFn();
        }
    }

    setAdConfig(adConfig: Object) {
        this.adConfig = adConfig;
        this.retryOnError = adConfig.retryOnError;
        this.setSoundState(!adConfig.mute);

        GoogleIMA.loadIMA()
            .then(() => {
                this.initImaSettings();
                this.isVideoLoaded = true;
                this.render();
                this.initAdsLoader();
            })
            .catch((err) => {
                dlog(err);
                this.videoError(err);
            });
    }

    getSkipButton() {
        const { isVideoPlaying } = this;
        return isVideoPlaying ? `
            <skip-button></skip-button>
        ` : '';
    }

    getPlayButton() {
        const { isVideoPlaying } = this;

        return !isVideoPlaying ? `
            <ark-pl class = "play-icon"></ark-pl>
        ` : `
            <ark-pl class = 'pause-icon'>
                <ark-pl></ark-pl>
                <ark-pl></ark-pl>
            </ark-pl>
        `;
    }

    getSoundButton(): string {
        const { isSoundOn } = this;
        return isSoundOn ? `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 115.3 115.3" style="enable-background:new 0 0 115.3 115.3;" xml:space="preserve">
                <g>
                    <path d="M47.9,14.306L26,30.706H6c-3.3,0-6,2.7-6,6v41.8c0,3.301,2.7,6,6,6h20l21.9,16.4c4,3,9.6,0.2,9.6-4.8v-77   C57.5,14.106,51.8,11.306,47.9,14.306z"/>
                    <path d="M77.3,24.106c-2.7-2.7-7.2-2.7-9.899,0c-2.7,2.7-2.7,7.2,0,9.9c13,13,13,34.101,0,47.101c-2.7,2.7-2.7,7.2,0,9.899   c1.399,1.4,3.199,2,4.899,2s3.601-0.699,4.9-2.1C95.8,72.606,95.8,42.606,77.3,24.106z"/>
                    <path d="M85.1,8.406c-2.699,2.7-2.699,7.2,0,9.9c10.5,10.5,16.301,24.4,16.301,39.3s-5.801,28.8-16.301,39.3   c-2.699,2.7-2.699,7.2,0,9.9c1.4,1.399,3.2,2.1,4.9,2.1c1.8,0,3.6-0.7,4.9-2c13.1-13.1,20.399-30.6,20.399-49.2   c0-18.6-7.2-36-20.399-49.2C92.3,5.706,87.9,5.706,85.1,8.406z"/>
                </g>
            </svg>
        ` : `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 461.55 461.55" style="enable-background:new 0 0 461.55 461.55;" xml:space="preserve">
                <g>
                    <g id="volume-off">
                        <path d="M345.525,229.5c0-45.9-25.5-84.15-63.75-102v56.1l63.75,63.75C345.525,239.7,345.525,234.6,345.525,229.5z M409.275,229.5 c0,22.95-5.1,45.9-12.75,66.3l38.25,38.25c17.85-30.6,25.5-68.85,25.5-107.1c0-109.65-76.5-201.45-178.5-224.4V56.1    C355.725,81.6,409.275,147.9,409.275,229.5z M34.425,0L1.275,33.15L121.125,153H1.275v153h102l127.5,127.5V262.65L340.425,372.3    c-17.851,12.75-35.7,22.95-58.65,30.601v53.55c35.7-7.65,66.3-22.95,94.35-45.9l51,51l33.15-33.149l-229.5-229.5L34.425,0z M230.775,25.5l-53.55,53.55l53.55,53.55V25.5z"/>
                    </g>
                </g>
            </svg>
        `;
    }

    get playerLayout(): string {
        return (`
            <ark-pl id = "ark_video_container"></ark-pl>
            <ark-pl class = "ark-player-controls">
                <button id = "ark-play-button" class = "play-button">
                    ${this.getPlayButton()}
                </button>
                <ark-pl class = "ark-progress-bar">
                    <ark-pl class = "ark-progress-bar-value"></ark-pl>
                </ark-pl>
                <button id = "ark-sound-button" class = "ark-sound-button">
                    ${this.getSoundButton()}
                </button>
            </ark-pl>
            ${this.getSkipButton()}
        `);
    }

    setSoundState = (state: boolean) => {
        dlog(`[setPlayState] isSoundOn = ${String(state)}`);
        const button = this.querySelector('#ark-sound-button');

        if (button) {
            this.isSoundOn = state;
            button.innerHTML = this.getSoundButton();
        }
    }

    setPlayState = (state: boolean) => {
        dlog(`[setPlayState] isPlaying = ${String(state)}`);
        const button = this.querySelector('#ark-play-button');

        if (button) {
            this.isVideoPlaying = state;
            button.innerHTML = this.getPlayButton();
        }

        if (state) {
            this.adsLoader.startProgressUpdateCycle();
        } else {
            this.adsLoader.stopProgressUpdateCycle();
        }
    }

    render() {
        this.innerHTML = `
            <ark-pl id = "player_ark_player_container">
                <ark-pl id = "player_ark_wrap">
                    ${this.isVideoLoaded ? this.playerLayout : ''}
                </ark-pl>
            </ark-pl>
        `;
    }

    onPlayClick = () => {
        if (this.adsLoader.adsManager) {
            if (this.isVideoPlaying) {
                this.adsLoader.adsManager.pause();
            } else {
                this.adsLoader.adsManager.resume();
            }
        }
    }

    onSoundClick = () => {
        if (this.adsLoader.adsManager) {
            if (this.isSoundOn) {
                this.adsLoader.adsManager.setVolume(0);
            } else {
                this.adsLoader.adsManager.setVolume(1);
            }
            this.setSoundState(!this.isSoundOn);
        }
    }

    onSkipClick = () => {
        dlog('Skip button clicked!');
        this.videoComplete();
    }

    logError = (message: string) => this.events.fire('Error', {
        message, retry: this.adConfig.retryOnError
    })

    videoLoaded = () => {
        dlog('[ev:VideoLoaded]');
        this.dispatchEvent(new CustomEvent('videoLoaded', { bubbles: true }));
        const playButton = this.querySelector('#ark-play-button');
        const soundButton = this.querySelector('#ark-sound-button');
        const skipButton = this.querySelector('skip-button');
        setEventHandler(skipButton, 'click', this.onSkipClick);
        setEventHandler(playButton, 'click', this.onPlayClick);
        setEventHandler(soundButton, 'click', this.onSoundClick);
        clearTimeout(this.adErrorTimer);
        this.adErrorTimer = setTimeout(() => {
            this.logError('Ad completed but no impression');
            if (!this.adConfig.retryOnNoImpression) {
                this.retryOnError = false;
                this.adConfig.retryOnError = false;
            }
        }, 12000);
    }

    videoStarted = ({ adsManager, adEvent }: Object) => {
        dlog('[ev:videoStarted]', adsManager.manager.H);
        this.dispatchEvent(new CustomEvent('videoStarted', { bubbles: true }));
        const ad = adEvent.getAd();
        const adDuration = ad ? ad.getDuration() : 0;
        this.adsLoader.startProgressUpdateCycle();
        this.adDuration = (
            adsManager.getRemainingTime() > 0
                ? adsManager.getRemainingTime() : adDuration
        );
        if (this.adDuration <= 0) this.adDuration = 30.0;
        this.adRemainingTime = adsManager.getRemainingTime();
    }

    videoImpression = () => {
        dlog('[ev:videoImpression]');
        this.dispatchEvent(new CustomEvent('videoImpression', { bubbles: true }));
        this.impressionFired = true;
        this.impressionFiredTime = new Date();
        clearTimeout(this.adErrorTimer);
    }

    videoPause = () => this.setPlayState(false);

    videoResume = () => this.setPlayState(true);

    videoError = ({ message }: { message: string }) => {
        dlog(`[ev:videoError] message = ${message}`);
        clearTimeout(this.adErrorTimer);
        this.retryOnError = false;
        this.logError(message);
        this.adConfig.retryOnError = false;
    }

    videoComplete = () => {
        dlog('[ev:videoComplete]');
        // add some time to allow video creative fire completion
        // pixels before destroying video player
        if (!this.isVideoCompleted) {
            this.isVideoCompleted = true;
            this.isVideoPlaying = false;
            dlog(`[ev:videoComplete] impressionFired = ${String(this.impressionFired)}`);
            if (!this.impressionFired) {
                if (!this.adConfig.retryOnNoImpression) {
                    this.retryOnError = false;
                }

                this.logError('Ad completed but no impression'); // we were no paid
                this.adConfig.retryOnError = false;
                return;
            }

            const impressionFiredUnder2Sec = !!(
                this.impressionFiredTime && (new Date() - this.impressionFiredTime < 2000)
            );

            dlog(`[ev:videoComplete] impressionFiredUnder2Sec = ${String(impressionFiredUnder2Sec)}`);

            if (impressionFiredUnder2Sec) {
                this.logError('Ad completion was fired under 2 seconds'); // house ad served? It's unlikely that paid ad will be so short
                this.adConfig.retryOnError = false;
                return;
            }

            dlog('[ev:videoComplete] set timeout for complete callback');
            setTimeout(() => {
                dlog('[ev:videoComplete] call complete method');
                this.complete();
            }, 500);
        }
    }

    videoProgress = ({ adsManager }: Object) => {
        const progressBar = this.querySelector('.ark-progress-bar-value');
        if (progressBar == null) {
            dlog('[ev:videoProgress] stop because no progress bar element');
            this.adsLoader.stopProgressUpdateCycle();
            return;
        }

        const remainingTime = adsManager.getRemainingTime();
        if (remainingTime !== this.adRemainingTime) {
            this.noUpdatesCount = 0;
            this.events.fire('video-playing', { time: this.adRemainingTime });
        }

        this.noUpdatesCount++;
        if (this.noUpdatesCount > this.adConfig.noUpdatesTimeoutSeconds * 5) {
            // kill video because it either frozen (thrown silent error)
            // or paused for too long or not sending required events
            dlog('[ev:videoProgress] stop because no updates from video');
            this.retryOnError = false;
            this.adsLoader.stopProgressUpdateCycle();
            return;
        }

        this.adRemainingTime = remainingTime;

        const rate = 100.0 - 100.0 * (this.adRemainingTime / (this.adDuration || 1));
        progressBar.style.width = `${Math.min(Math.max(rate, 0), 100)}%`;
    }

    complete() {
        dlog('[complete]');
        clearTimeout(this.adErrorTimer);
        this.adsLoader.stopProgressUpdateCycle();
        this.adsLoader.destroy();
        this.dispatchEvent(new CustomEvent('videoCompleted'));
    }

    connectedCallback() {
        this.render();
    }
}

if (!customElements.get('akr-ad-video-player')) {
    customElements.define('akr-ad-video-player', Player);
}
