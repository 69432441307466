// @flow

export class AdConfigBase {
    __fields = {};

    constructor() {
        this._fillFieldsList();
    }

    _fillFieldsList() {
        Object.keys(this)
            .forEach((key: string) => {
                this.__fields[key.toLowerCase()] = key;
            });
    }

    fromJSON(options: Object = {}) {
        Object.keys(options)
            .map(key => this.__fields[key.toLowerCase()])
            .filter(key => !!key)
            .forEach((key: string) => {
                if (key in this && key !== 'fromJSON' && key !== '__fields' && key !== 'clone') {
                    // $FlowFixMe
                    const val = typeof options[key.toLowerCase()] === 'undefined' ? options[key] : options[key.toLowerCase()];
                    /* $FlowFixMe */
                    this[key] = typeof val === 'undefined' ? this[key] : val;
                }
            });
    }

    clone() {
        const configClone = new this.constructor();
        configClone.fromJSON({ ...this });
        return configClone;
    }
}
