// @flow

import { AdConfigBase } from './base';

type RewardSetting = {
    isDesktopPlatform?: boolean;
    showAsMidroll?: boolean;
    showGoogleReward?: boolean;
    applicationKey?: string;
    rewardId?: string;
}

interface GDPR {
    enabled: boolean;
    personalizedAds: boolean;
}

type CTAScreenConfig = {
    customTagline?: string;
    containerClass?: string;
    buttonClass?: string;
    buttonTextClass?: string;
    buttonArrowClass?: string;
    messageBoxClass?: string;
    messageText?: string;
    buttonText?: string;
    colLeftClass?: string;
    colRightClass?: string;
}

export class AdVideoConfig extends AdConfigBase {
    constructor() {
        super();
        this._fillFieldsList();
    }

    adUnit: string = '';

    adUrl: string = '';

    aspectRatio: number = 0.56;

    autoPlay: boolean = false;

    dfpNetworkCode: string = '100151972';

    elementId: string = 'ark_video_pre-roll';

    $element: ?Object = null;

    errorAnalytics: boolean = true;

    firstLook: boolean = false;

    fitContainerHeight: boolean = false;

    headerBidding: boolean = true;

    keyValues: any[][] = [];

    noUpdatesTimeoutSeconds: number = 40;

    mobileFullScreen: boolean = false;

    mute: boolean = false;

    pageUrl: string = '';

    product: string = 'phoenix';

    retryOnError: boolean = true;

    retryOnNoImpression: boolean = false;

    type: string = 'pre-roll';

    reward: RewardSetting = {};

    volume: number = 1;

    vpaidInsecure: boolean = false;

    adParamsFn: () => string;

    CTAScreenConfig: CTAScreenConfig = {};

    gdpr: GDPR = {
        enabled: false,
        personalizedAds: true,
    };
}
