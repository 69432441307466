export class EventEmitter {
    events = {};

    addEventListener(event, callback) {
        const list = this.events[event] || [];
        list.push(callback);
        this.events[event] = list;
    }

    removeEventListener(event, callback) {
        const list = this.events[event] || [];
        list.splice(list.indexOf(callback), 1);
        this.events[event] = list;
    }

    fire(event, data) {
        (this.events[event] || []).forEach(fn => fn(data));
    }
}
