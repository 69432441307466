// @flow

import debug from '../debug';

import { AdUnit } from './units';

const dlog = debug('arkadium-ads:providers:prebid');

export type PrebidCallback = (any) => any

export interface PrebidLib {
    adServers: any;
    requestBids: ({
        timeout: number,
        adUnitCodes: string[],
        bidsBackHandler: (any) => void
    }) => void,
    adUnits: any,
    aliasBidder: (string, string) => void,
    setTargetingForGPTAsync: (any) => void,
    setConfig: (any) => void,
    addAdUnits: (string|string[]) => void,
    removeAdUnit: (string) => void,
    enableAnalytics: (any[]) => void,
    buildVideoUrl: (any) => any,
    getAdserverTargeting: (any) => any,
    onEvent: (string, any) => any,
    renderAd: (HTMLElement, string) => any,
    que: PrebidCallback[],
    bidderSettings: Object
}

export class Prebid {
    BID_TIMEOUT: number = 1200;

    _instance: ?PrebidLib = null;

    constructor(prebid: ?PrebidLib, settings: Object, config: Object) {
        this._instance = prebid;
        if (settings) {
            this.setSettings(settings);
        }

        if (config) {
            this.setConfig(config);
        }
    }

    aliasBidder() {
        // openweb is currently only being tested on arkadium.com
        if (window.location.hostname !== 'www.arkadium.com') return;
        // $FlowFixMe
        this._instance.aliasBidder('appnexus', 'openweb_appnexus');
        // $FlowFixMe
        this._instance.aliasBidder('ix', 'openweb_ix');
        // $FlowFixMe
        this._instance.aliasBidder('pubmatic', 'openweb_pubmatic');
        // $FlowFixMe
        this._instance.aliasBidder('rubicon', 'openweb_rubicon');
        // $FlowFixMe
        this._instance.aliasBidder('spotx', 'openweb_spotx');
    }

    addAdUnit(unit: AdUnit): boolean {
        if (this._instance) {
            if (this._instance.adUnits.find(ad => ad.code === unit.code)) return false;
            // $FlowFixMe
            this._instance.addAdUnits(unit);
            return true;
        }

        return false;
    }

    renderAd(element: HTMLElement, adId: string) {
        if (this._instance) {
            this._instance.renderAd(element, adId);
        }
    }

    removeAdUnit(adUnitCode: string) {
        dlog(`[removeAdUnit] item: ${adUnitCode}`);
        if (this._instance) {
            this._instance.removeAdUnit(adUnitCode);
        }
    }

    setTargetingForGPTAsync(adUnitCodes: string[]) {
        dlog(`[setTargetingForGPTAsync] items: ${adUnitCodes.toString()}`);
        if (this._instance) {
            this._instance.setTargetingForGPTAsync(adUnitCodes);
        }
    }

    getAdserverTargeting(): any {
        dlog('[getAdserverTargeting]');
        if (this._instance) {
            return this._instance.getAdserverTargeting();
        }

        return {};
    }

    buildVideoUrl(config: Object): any {
        if (this._instance) {
            return this._instance.adServers.dfp.buildVideoUrl(config);
        }

        return '';
    }

    requestBids(adUnitCodes: string[] = []): Promise<any> {
        return new Promise((resolve, reject): any => {
            if (adUnitCodes.length === 0) return resolve([]);

            dlog(`[requestBids] ${adUnitCodes.toString()}`);
            if (this._instance) {
                return this._instance.requestBids({
                    timeout: this.BID_TIMEOUT,
                    adUnitCodes,
                    bidsBackHandler: resolve,
                });
            }

            return reject(new Error('There is no prebid instance'));
        });
    }

    addAction(action: any) {
        dlog('[addAction]');
        if (this._instance) {
            this._instance.que.push(() => action());
        }
    }

    setSettings(settings: Object) {
        dlog(`[setSettings] ${JSON.stringify(settings)}`);
        if (this._instance) {
            this._instance.bidderSettings = settings;
        }
    }

    setConfig(config: Object) {
        dlog(`[setConfig] ${JSON.stringify(config)}`);
        if (this._instance) {
            this._instance.setConfig(config);
        }
    }

    enableAnalytics(trackerId: string) {
        dlog(`[enableAnalytics] ${trackerId}`);
        if (this._instance) {
            this._instance.enableAnalytics([
                {
                    provider: 'ga',
                    options: {
                        global: window.GoogleAnalyticsObject,
                        trackerName: trackerId, // GA tracker
                        enableDistribution: false,
                    },
                },
            ]);
        }
    }
}
