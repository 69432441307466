// @flow

import { AdVideoConfig } from '../configs/video-config';
import { AdUnit } from '../providers/units';

export const State = {
    CREATED: 'CREATED',
    DISPLAYED: 'DISPLAYED',
    PROCESSING: 'PROCESSING',
};

export const Providers = {
    GTM: 'GTM',
    GLADE: 'GLADE',
};

export const AdType = {
    VIDEO: 'VIDEO',
    DISPLAY: 'DISPLAY',
};

export type AdTypeEnum = $Keys<typeof AdType>;

export type StateType = $Keys<typeof State>;

export type ProviderType = $Keys<typeof Providers>;

export class AdQueueItem {
    type: AdTypeEnum = AdType.DISPLAY;

    targetProvider: ProviderType = Providers.GTM;

    state: StateType = State.CREATED;

    componentId: string = '';

    adConfig: any = new AdVideoConfig();

    adUnit: AdUnit = new AdUnit();

    amazonSupported: boolean = true;
}
